<template>
  <div class="home">
    <!-- logo 点击回到主页 -->
    <div
      class="logo"
      @click="gohome"
    >
      <img
        src="../../assets/logo/图层 1@2x.png"
        class="logoImg"
      />
    </div>
    <!-- navbar 导航栏 -->
    <div class="navmenu">
      <el-menu
        background-color="#000000"
        text-color="#fff"
        default-active="/home"
        class="el-menu-demo"
        mode="horizontal"
        :collapse="isCollapse"
        router
        @select="handleSelect"
        ref="refMenu"
      >
        <el-submenu index="/phantas">
          <template slot="title">{{ this.$t("nav.cleaningRobot") }}</template>
          <el-menu-item index="/phantas">Phantas</el-menu-item>
          <el-menu-item index="/vacuum40">Vacuum 40</el-menu-item>
          <el-menu-item index="/scrubber50">Scrubber 50</el-menu-item>
          <el-menu-item index="/scrubber75">Scrubber 75</el-menu-item>
          <el-menu-item index="/sweeper111">Sweeper 111</el-menu-item>
        </el-submenu>
        <el-submenu index="/delivery">
          <template slot="title">{{ this.$t('nav.delivery') }}</template>
          <el-menu-item index="/deliveryx1">Delivery X1</el-menu-item>
        </el-submenu>
        <el-submenu index="/tv">
          <template slot="title">{{ this.$t("nav.smartHome") }}</template>
          <el-menu-item index="/tv">MAXHUB</el-menu-item>
          <el-menu-item index="/speaker">eMeet Meeting Capsule</el-menu-item>
        </el-submenu>
        <el-menu-item index="/robotDog">{{
          this.$t("nav.robot")
        }}</el-menu-item>
        <el-menu-item index="/car">{{ this.$t("nav.car") }}</el-menu-item>
        <el-menu-item index="/news">{{ this.$t("nav.news") }}</el-menu-item>
        <el-submenu index="/information">
          <template slot="title">{{ this.$t("header.information") }}</template>
          <el-menu-item index="/information">{{
            this.$t("header.information")
          }}</el-menu-item>
          <el-menu-item index="/contact">{{
            this.$t("header.inquiry")
          }}</el-menu-item>
        </el-submenu>
        <el-menu-item class="menu">
          {{ choose }}
          <div class="menu_item">
            <div
              class="drop"
              @click="changeType('JP')"
              :class="{ active: choose === 'ja' }"
            >
              JP
            </div>
            <div
              class="drop"
              @click="changeType('EN')"
              :class="{ active: choose === 'en' }"
            >
              EN
            </div>
            <div
              class="drop"
              @click="changeType('CH')"
              :class="{ active: choose === 'zh' }"
            >
              CH
            </div>
          </div>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="hamberger">
      <div class="center">
        <el-dropdown
          placement="bottom"
          trigger="click"
        >
          <span class="el-dropdown-link">
            {{ choose }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <div @click="changeType('JP')">JP</div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="changeType('EN')">EN</div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="changeType('CH')">CH</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <i
          class="el-icon-s-operation"
          @click="changeHambergerVisible"
        ></i>
      </div>
    </div>
    <el-dialog
      class="hambergerDialog"
      :title="this.$t('footer.directory')"
      :visible.sync="hambergerVisible"
      width="100%"
      :modal-append-to-body="false"
      top="0"
      :before-close="handleClose"
    >
      <el-menu
        class="el-menu-vertical-demo"
        background-color="#000000"
        text-color="#fff"
        default-active="/home"
        unique-opened
        router
        @select="handleSelect"
        ref="refDialogMenu"
      >
        <el-menu-item index="/home">{{ this.$t("nav.gohome") }}</el-menu-item>
        <el-submenu index="/phantas">
          <template slot="title">
            <span>{{ this.$t("nav.cleaningRobot") }}</span>
          </template>
          <el-menu-item index="/phantas">Phantas</el-menu-item>
          <el-menu-item index="/vacuum40">Vacuum 40</el-menu-item>
          <el-menu-item index="/scrubber50">Scrubber 50</el-menu-item>
          <el-menu-item index="/scrubber75">Scrubber 75</el-menu-item>
          <el-menu-item index="/sweeper111">Sweeper 111</el-menu-item>
          <el-menu-item-group> </el-menu-item-group>
        </el-submenu>
        <el-submenu index="/delivery">
          <template slot="title">{{ this.$t ('nav.delivery') }}</template>
          <el-menu-item index="/deliveryx1">Delivery X1</el-menu-item>
        </el-submenu>
        <el-submenu index="/tv">
          <template slot="title">
            <span>{{ this.$t("nav.smartHome") }}</span>
          </template>
          <el-menu-item index="/tv">MAXHUB</el-menu-item>
          <el-menu-item index="/speaker">eMEET Meeting Capsule</el-menu-item>
          <el-menu-item-group> </el-menu-item-group>
        </el-submenu>

        <el-menu-item index="/robotDog">{{
          this.$t("nav.robot")
        }}</el-menu-item>
        <el-menu-item index="/car">{{ this.$t("nav.car") }}</el-menu-item>
        <el-menu-item index="/news">{{ this.$t("nav.news") }}</el-menu-item>
        <el-submenu index="/information">
          <template slot="title">{{ this.$t("header.information") }}</template>
          <el-menu-item index="/information">{{
            this.$t("header.information")
          }}</el-menu-item>
          <el-menu-item index="/contact">{{
            this.$t("header.inquiry")
          }}</el-menu-item>
        </el-submenu>
      </el-menu>
    </el-dialog>
  </div>
</template>

<script>
import ColNavBar from "@/components/colNavbar/index.vue";
export default {
  components: {
    ColNavBar,
  },
  data() {
    return {
      // 语言
      choose: localStorage.getItem("locale") || "JP",
      // el-menu 默认选中索引
      defaultActive: "/",
      // 对照关系
      contrast: {
        1: {},
        2: "/tv",
        3: "/robotDog",
        4: "/car",
        5: "/enterprise",
      },
      isCollapse: false,
      hambergerVisible: false,
      activeIndex: this.$route.fullPath,
    };
  },
  created() {},
  mounted() {
    // 监听窗口大小变化，根据屏幕宽度设置isCollapse的值
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  // 监听route，修改当前Menu组件activeIndex，实现响应式
  watch: {
    $route(to, from) {
      this.$refs.refMenu.activeIndex = to.fullPath;
      this.activeIndex = to.fullPath;
      document.documentElement.scrollTop = 0;
    },
    //打开导航栏时候处理列表函数
    hambergerVisible(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.refDialogMenu.openedMenus.push(this.activeIndex);
          this.$refs.refDialogMenu.activeIndex = this.activeIndex;
          if (
            this.$route.fullPath === "/rules" ||
            this.$route.fullPath === "/privacyPolicy"
          ) {
            this.$refs.refDialogMenu.openedMenus.splice(0);
            this.$refs.refDialogMenu.activeIndex = "";
          }
        });
      }
    },
  },
  methods: {
    gohome() {
      if (this.$route.path === "/home") return;
      else {
        this.$router.push("/home");
        this.$refs.refMenu.activeIndex = "/home";
      }
    },
    // el-menu切换事件
    handleSelect(key, keyPath) {
      if (this.hambergerVisible) {
        this.hambergerVisible = false;
      }
      if (
        this.isCollapse &&
        (key === "/car" ||
          key === "/robotDog" ||
          key === "/home" ||
          key === "/news")
      ) {
        this.$refs.refDialogMenu.openedMenus.splice(0);
      }
    },
    // 切换语言方法
    changeType(type) {
      // 此处做了语言选择记录
      localStorage.setItem("locale", type);
      // 修改显示语言
      this.$i18n.locale = type;
      this.chose = type;
      // 修改显示语言
      this.choose = type;
      this.defaultActive = null;
    },
    handleResize() {
      if (window.innerWidth < 860) {
        this.isCollapse = true;
      } else {
        this.isCollapse = false;
      }
    },
    // hamberger Dialog弹窗事件
    handleClose(done) {
      this.hambergerVisible = !this.hambergerVisible;
    },
    // hamberger按钮切换弹窗
    changeHambergerVisible() {
      this.hambergerVisible = !this.hambergerVisible;
    },
    changeLanguage(key, keyPath) {},
  },
};
</script>

<style scoped lang='less'>
.home {
  // box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // height: 80px;
  background-color: #000;
  .logo {
    margin-right: 20px;
    height: 80px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .logoImg {
      height: 25px;
    }
  }
  .navmenu {
    /deep/.el-menu {
      display: flex;
      height: 80px;
      background-color: #000;
      .el-menu-item {
        height: 80px;
        line-height: 80px;
        color: #e5e5e5;
        //设置高亮菜单的样式
        &.is-active {
          background: #111;
          color: #fff;
        }
        //设置鼠标移到菜单时的样式变化
        &:focus,
        &:hover,
        &:hover i {
          background: #111 !important;
          color: #fff;
        }
      }
      .el-submenu {
        height: 80px;
        background-color: #000;
        .el-submenu__title {
          color: #fff;
          height: 80px !important;
          line-height: 80px !important;
          background-color: #000;
          i {
            color: #fff;
          }
          &:hover,
          &:hover i,
          &:focus {
            background: #111 !important;
            color: #fff;
          }
        }
      }
      &.el-menu--horizontal {
        border: 0;
      }
      //  语言栏
      .menu {
        text-align: center;
        margin-left: 10px;
        z-index: 99;
        overflow: hidden; /*必须用overflow,不然内容会挤出去，就算设置高度为零也没意义*/
        float: right;
        transition: 0.2s;
        width: 2rem !important;
        padding: 0;
        .drop {
          color: #ffffff9d;
        }
      }
      .menu:hover {
        height: 180px;
        cursor: pointer;
        transition: ease-in-out 0.2s;
        border-radius: 40px;
        overflow: visible;
      }
      .menu_item {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: left;
        align-items: center;
        .drop {
          line-height: 30px;
          font-weight: 800;
        }
        .drop:hover {
          line-height: 30px;
          color: #ffffff;
        }
      }
    }
  }
  .hamberger {
    display: none;
    cursor: pointer;
    font-size: 32px;
    color: #fff;
    .center {
      display: flex;
      align-items: center;
      /deep/ .el-dropdown {
        margin-right: 10px;
        .el-dropdown-link {
          font-size: 24px;
          line-height: 1;
          cursor: pointer;
          color: #fff;
          div {
            width: 100%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .logo {
      margin-left: 10px;
    }
    .el-menu {
      margin-right: 10px;
    }
  }
  // 当屏幕宽度小于860px时
  @media screen and (max-width: 860px) {
    .home {
      height: 60px;
    }
    .navmenu {
      display: none;
    }
    .logo {
      height: 60px;
      margin: 0 10px;
    }
    .hamberger {
      padding: 0 8px;
      display: block;
    }
  }
  .hambergerDialog {
    /deep/.el-dialog {
      background-color: #000;
      .el-dialog__header {
        .el-dialog__title {
          color: #fff;
        }
        .el-dialog__headerbtn .el-dialog__close {
          font-size: 25px;
          color: #fff;
        }
      }
      .el-dialog__body {
        padding-top: 0;
      }
      .el-menu {
        margin: 0;
        border: 0;
      }
    }
  }
}
/deep/.el-dropdown-menu__item {
  padding: 0;
  width: 46px;
  font-size: 18px;
  text-align: center;
}
</style>
