export default {
   MAXHUB: {
      HighQuality: '高画質、超大画面。スムーズな書き心地。<br/> そして、マルチデバイスと双方向につながる。<br/>その会議が、活き活きと動き出す。優れた頭脳を持ち始める。<br/>いままでにないアイディアを発想する、MAXHUB。<br/>この一枚のパネルが、いまオフィスに革新をもたらせる。',
      MmUltraThin: '17.8 mm超薄型',
      PixelHDR: '4,800万画素HDRカメラ',
      ANewSeam: '新型シームスピーカー',
      boxA: {
         title: "デスクトップアプリカスタマイズ",
         content: 'デスクトップのアプリメニューをカスタマイズできる。',
      },
      boxB: {
         title: 'Wi-Fiシールドモードで接続保護',
         content: 'ホットスポットとしてMAXHUBへ接続しているデバイ スが、社内インターネットに接続することを防ぐことが できるのでセキュリティー面も安心。',
      },
      boxC: {
         title: '最大6画面の同時投影',
         content: '複数のデバイスから最大６画面まで同時に投影できるので、画面を切り替える手間がなくなる。',
      },
      boxD: {
         title: 'マルチメディアインポート<br/>(画像、動画、PDF、パワーポイント)',
         content: '画像だけではなく、動画、PDF、パワーポイント※1などのマルチファイルをホワイトボートに挿入可能。<br/> <br/>※1 Microsoft officeを別途インストールする必要があります。',
      },
      boxE: {
         title: 'QRコードでファイルスピード転送',
         content: 'QRコードを手元のデバイスで読み取ることで、すばやくMAXHUBに転送することができる。',
      },
      boxF: {
         title: 'メモ転送機能',
         content: 'QRコードをスキャンすることで、遠隔地からもMAXHUBにメモを転送できる。',
      },
      TheScreen: '画面比率が高くなり※2、超薄型設計になっています。<br/> オプティカルボンディング技術により視差を最小限に抑え、鮮明な画像品質を実現し、これまでにない没入感を体験できます。<br/><br/>4 K UHDディスプレイ｜PCAPタッチ技術｜17.8 mmまで低く｜光学結合｜アンチグレアガラス<br/><br/>※2 MAXHUB第三世代との比較となります。',
      ForPrivacy: 'プライバシーとパフォーマンスのために、最大16ギガバイトDDR4-4,800万画素フリップオーバーカメラを採用しており、WEB会議アプリケーションを起動すると カメラが自動的に上昇します。<br/><br/>自動増幅スピーカー｜より高いSNR｜必要に応じて前面にフリップするだけ',
      HighPower: '40W※3の高出力スピーカーとさらに向上したオーディオアルゴリズムにより、プロフェッショナルなスタジオ品質のようなオーディオ体験を実現しました。<br/><br/>2.1ステレオ｜40 W強力スピーカー <br/><br/>※3 スピーカー出力は2×10W+20W（ウーファー）です。',
      EquippedWith: '6つのアレイマイク、8mの集音範囲、定位技術を搭載し、隅からでも前方からでも正確に集音できます。発言者とマイクとの距離に応じて、スピーカーの音量を適切レベルに調整してくれるAGC機能※4も搭載し、会議参加者の声をリアルに再現します。<br/><br/>6コンポーネントマイクアレイ｜高さ8メートルの音声ピックアップ｜音声ローカライズ｜自動ゲイン｜ノイズ除去<br/><br/>※4 AGC（オートゲインコントロール）',
      FeaturesAnd: 'デジタルならではの機能や書き心地で<br/>快適な操作性を実現<br/><br/>ワイヤレスドングル、専用アプリケーション「MAXHUB Share」、Type-C、X-linkなど、様々な接続方式で簡単に素早く画面共有可能。双方向で書き込み・操作ができるため、リアルタイムで正確な意思疎通ができます。<br/><br/>MAXHUBワイヤレス暗号化犬｜MAXHUB共有｜C型ポート｜X-link',
      PlugableDesign: 'プラグイン可能な設計により、必要なメンテナンスとアップグレードが簡単に行えます。拡張性と互換性に優れたWindowsを搭載しているため、 現行の社内システムから無理なく使用できます。<br/><br/>Windows 10',
      ImageSet: '製品設置イメージ',
      ConferenceRoomMiddle: '中会議室',
      TheConference: '具体的な議論、明確な意思決定を必要とする会議には、中会議室はとても適しています。',
      ConferenceRoomHigh: '大会議室',
      InTheConference: '大会議室には、発表、グループ報告、交渉などのために多くの関係者が集まります。<br/>MAXHUBを使えば、簡単にアイデアを共有でき、その場にいない人とも容易につながることができます。',
      RecommendedSize: '推奨サイズ',
   },
   EMEET: {
      EmetOptimized: 'eMeet   最適化された360度オーディオとビデオカバー',
      TheExclusiveAI: '独占AIアルゴリズムは,音声に応答するために，ディスプレイ上で活発な話者の顔を完璧に捉えて提示し，チーム全体により効率的なコミュニケーションを提供する.',
      ToSee: '部屋全体を見るには',
      The360Degree: '360° 会議カメラは、4K 解像度で半径 13 フィート (4 m) 以内のすべての詳細をキャプチャし、HD1080P 画像を出力し、死角なしで会議室全体を明確にカバーします。',
      SoundClearly: 'はっきりと聞こえます',
      SmartOmnidirectional: '8 つのスマートな無指向性マイクが、半径 5.5 メートル (18 フィート) 以内のあらゆる角度から音声を拾い、音声品質を次のレベルに引き上げます。',
      ListenToAll: 'すべての音節を聞く',
      The10W90dB: '10W/90dB のスピーカーは、Hi-Fi グレードのサウンドをより大きくクリアに、細部までクリアに耳に届けます。',
      originalCreation: '独創的なサスペンド キャビティ設計により振動ノイズが回避されるため、マイクとスピーカーの両方が完璧に機能します。',
      MaximizingPerformance: 'パフォーマンスの最大化 生産性の最適化',
      BestProfessional: '最高のプロフェッショナル ハンズフリー電話、360° ピックアップ ポジショニング、リング ライト付き、48kHz サンプリング レート、独自の DSP アルゴリズム VoiceIA®、初のフローティング マイク キャビティ設計、高性能スピーカー、非常に使いやすく、中規模および大規模の会議室に適しています。',
   },
   home: {
      AutoSpot: 'オートスポットクリーニング',
      QuadruplEefficiency: '4倍の効率化',
      PhantasExplores: 'Phantas は、Gausiumの画期的な「オートスポット清掃」を経路探索に組み込んでいます。このモードでは、ロボットが近くのフロアの清潔度をスキャンし、ゴミが検出された場所を自律的にスポット清掃します。また、清掃しきれない大きさのゴミを発見した場合は、アプリを通じてオペレーターにメッセージを送信します。 必要な場所のみを清掃することで、Phantas は最大400%の効率改善をもたらし、水、エネルギー、化学薬品の消費を大幅に削減します。',
      SeeMorel: 'もっと見る >',
      RobotWith: 'AI搭載のロボット床磨き機',
      AnExper: 'ロボット掃除機のエキスパート',
      CleaningOil: '油汚れのクリーニング',
      OutdoorCleaning: '屋外清掃',
      OtherAreas: 'その他の領域',
      HighQuality: '高画質、超大画面。スムーズな書き心地。',
      Degree: '360度回転カメラ',
      SpeechRecognition: '音声認識，顔認証，ジェスチャ認識',
      InVehicle: '車載',
      FullScreen: 'フルスクリーン， フルビジョン',
   },
   phantas: {
      SmartObstacle: 'スマートな障害物回避',
      PhantasDetects: 'Phantas は、あらゆる方向からの静的・動的な障害物を検知し、リアルタイムで自律的なスマートアボイダンスを行って、作業スペース内に衝突のない経路を生成します。市販の多くの清掃ロボットとは異なり、Phantas はディープラーニングベースのアルゴリズムを搭載し、数百万枚の実写画像で訓練されています。堅牢な障害物認識能力を持ち、障害物の種類や特徴に応じて行動を決定します。例えば、電線を直接通過させるのではなく、迂回させるなど。',
      OneStop: 'ワンストップセルフサービス',
      WhenCombined: 'Phantas は、充電ドックと組み合わせることで、操作時の人の介入を最小限に抑えます。ドッキングステーションと自律的に接続することで、セルフサービスを行うことが可能です。オプションで水タンクトロリーを用意し、水補給の手間を省くことができます。 ドッキングステーションと自律的に接続することで、セルフサービスを行うこと。',
      AutoSpot: 'オートスポットクリーニング',
      QuadrupleEfficiency: '4倍の効率化',
      PhantasExplores: 'Phantas は、Gausiumの画期的な「オートスポット清掃」を経路探索に組み込んでいます。このモードでは、ロボットが近くのフロアの清潔度をスキャンし、ゴミが検出された場所を自律的にスポット清掃します。また、清掃しきれない大きさのゴミを発見した場合は、アプリを通じてオペレーターにメッセージを送信します。 必要な場所のみを清掃することで、Phantas は最大400%の効率改善をもたらし、水、エネルギー、化学薬品の消費を大幅に削減します。',
      PhantasS1: 'Phantas S1 仕様',
      ProductSize: '商品のサイズ：',
      Products: '製品正味重量：',
      SensorConfiguration: 'センサー構成：',
      KiyoaraiWidth: '清洗幅：',
      use: 'レーザレーダ、奥行きカメラ、カラーカメラ、落下防止、衝突防止',
      pleonasm_title: 'Gaussian Robotics社製「掃除ロボット」「配膳ロボット」× ENWAY',
      pleonasm_content: '2022年にGaussian Robotics社とENWAYは業務提携し、ロボットの導入を始めました。<br/>「掃除ロボット」のシステム開発について、弊社が携わっております。<br/><br/>2023年よりENWAYは、Gaussian Robotics社製「掃除ロボット」「配膳ロボット」を日本への提供を本格的に開始いたしました。'
   },
   vacuum40: {
      vacuum40: '商用ロボット',
      ItIsCompatible: '屋内の硬い床やカーペットに対応し、掃除機をかける、掃く、ゴミを押し出すなどの多機能を備えたお掃除ロボットで、オフィスビル、ホテル、展示場、会議場、学校、空港などで広く利用されています。',
      ThoroughCleaning: '徹底した洗浄と空気清浄',
      EachFunction: '-1台で多機能:清掃·集塵·推進塵一体化。<br/> -軟硬地面互換性：大理石、水磨石、タイル、カーペット。<br/> -霧化消殺：超音波霧化消毒、除菌効率が高い<br/> -全行程無人化：自主充電、ゲート通過、エレベーターに乗る。 <br/>-スマート化管理：タスクスケジューリング，タイミングタスク，遠隔運用をサポートする。',
      SimplifyingWorkflow: 'ワークフローの簡素化',
      Vacuum40Can: 'Vacuum 40 は、人間の手をほとんど煩わせることなく、掃除を簡単にすることができます。環境の変化を感知し、リアルタイムで地図を更新してルートを変更するので、立ち往生しているところを助けてあげる必要はありません。オプションの充電ドックを使用すれば、ロボットが自分で充電することも可能です。また、Gausium モバイルアプリによるリモートアクセスも可能で、どこからでも清掃作業を監視・制御することができます。',
      Vacuum40Specifications: 'Vacuum 40 仕様',
      ProductSize: '商品のサイズ：',
      BatteryCapacity: 'バッテリー容量：',
      ContinuousUptime: '連続稼働時間：',
      KiyoaraiWidth: '清洗幅：',
      SensorConfiguration: 'センサー構成：',
      DeviceDisposition: 'レーザレーダ*1、奥行きカメラ*3、トップビューカメラ（オプション）、電子衝突防止ストリップ、落下防止'
   },
   scrubber50: {
      scrubber50: '商用清掃ロボット',
      RobotsForCleaning: '室内のハードフロアの洗浄、掃除、ほこりを払うロボットは、ショッピングモール、スーパーマーケット、病院、オフィスビル、ホテル、交通拠点、工業地帯、学校など、広範囲にわたるシーンに適用されます。',
      Scrubber50Specification: 'Scrubber 50 仕様',
      ProductSize: '商品のサイズ：',
      BatteryCapacity: 'バッテリー容量：',
      ContinuousUptime: '連続稼働時間：',
      KiyoaraiWidth: '清洗幅：',
      SensorConfiguration: 'センサー構成：',
      ClimbingSlope: '傾斜：',
      dispose: 'レーザレーダ*1、奥行きカメラ*3、両側奥行きカメラ（オプション）、トップビューカメラ（オプション）、電子衝突防止ストリップ、落下防止、負圧センサー'
   },
   scrubber75: {
      scrubber75: '商用清掃ロボット',
      ThisCleaning: '室内外の中規模および大規模のシーンに対応したクリーニングロボットで、広場、空港、駅、駐車場、工場、倉庫、スーパーマーケット、学校などの屋内外の様々な場面に適用できます。床の清掃、油汚れの除去、塵の除去、異物の詰まり防止などの機能を持っています。',
      Scrubber75Specification: 'Scrubber 75 仕様',
      ProductSize: '商品のサイズ：',
      BatteryCapacity: 'バッテリー容量：',
      ContinuousUptime: '連続稼働時間：',
      KiyoaraiWidth: '清洗幅：',
      SensorConfiguration: 'センサー構成：',
      configuration: '3 Dレーザレーダ、深度カメラ、2 Dレーザレーダ、ミリ波レーダ、電子衝突防止バー'
   },
   sweeper111: {
      sweeper111: '商用清掃ロボット',
      RobotsForCleaning: '室内のハードフロアの洗浄、掃除、ほこりを払うロボットは、ショッピングモール、スーパーマーケット、病院、オフィスビル、ホテル、交通拠点、工業地帯、学校など、広範囲にわたるシーンに適用されます。',
      Sweeper111Specification: 'Sweeper 111 仕様',
      ProductSize: '商品のサイズ：',
      BatteryCapacity: 'バッテリー容量：',
      ContinuousUptime: '連続稼働時間：',
      KiyoaraiWidth: '清洗幅：',
      TurningAngle: '転向角度：',
   },
   news: {
      newsCenter: 'プレスセンター',
      learnMore: 'もっと見る',
      noData: '現在データがありません',
      bannerTitle: 'Gaussian Robotics社製「掃除ロボット」「配膳ロボット」の日本への提供を本格的に開始',
      bannerContent: '2023年よりGaussian Robotics社製「掃除ロボット」「配膳ロボット」において、弊社が本格的に日本への提供を開始いたしました。Gaussian Robotics社とは2022年に業務提携し、昨年よりロボットの導入を始めました。なお「掃除ロボット」のシステム開発についても弊社が携わっております。'
   },
   header: {
      information: '会社概要',
      inquiry: 'お問い合わせ',
   },
   footer: {
      privacyPolicy: 'プライバシーポリシー',
      rules: '利用規約',
      directory: 'ディレクトリ',
      privacyClause: 'プライバシーポリシー 利用規約',
      location: '所在地'
   },
   nav: {
      car: '車載',
      phone: 'スマホ',
      robot: '教育ロボット',
      smartHome: 'ホームインテリジェント',
      cleaningRobot: 'クリーニングロボット',
      news: 'NEWS',
      gohome: 'ホームページに戻る',
      delivery:'配膳ロボット'
   },
   car: {
      car: '車載',
      FullScreen: 'フルスクリーン',
      FullView: 'フルビジョン',
      math: 'デジタルメーター ',
      adas: 'ADAS先進',
      safe: '運転支援システム',
      system: 'システム構成',
      navigation: 'ナビゲーション'

   },
   phone: {
      phone: 'スマホ',
      second: '0.1秒スナップショット',
      noUse: 'ボタンは不要です',
      ai: 'AI 画像ノイズ除去',
      lowLight: '暗い環境',
      fillLight: '自動充填ライト',
      beauty: '美肌機能',
      tracking: 'リアルタイム追跡機能',
      focus: '主人公も焦点'
   },
   robot: {
      robot: 'ロボット',
      math: 'AIのアルゴリズム',
      identify: '音声認識，顔認証，ジェスチャ認識',
      foot: '自由度の高いロボット',
      eye: 'ビジュアルアイデンティティ',
      gesture: 'ジェスチャ認識、顔追跡、ビジュアルプログラミング環境',
      OpenSource: 'オープンソース',
      any: 'PCを使ってカスタマイズ可能、体を傾けたり、回ったり、跳ねたり動作が可能',
      person: 'カスタマイズ',
      imagine: '無限の想像力があなたにユニークなデザインをもたらします',
      robotDog: 'ミニティッツ：オープンソース、ROSロボットドッグキット'
   },
   smartHome: {
      tv: {
         tv: 'テレビ',
         title: '戦略を立て',
         title_second: '時空を超える',
         voice: 'インテリジェントな音声制御、席を離れることはありません',
         voice_second: '音源定位、エコーキャンセレーション、インテリジェントノイズリダクション',
         face: 'スマート フェイストラッキング',
         face_second: 'カメラを制御して顔を追跡し、インテリジェントな制御と追跡を実現します',
         meet: '複数のアプリケーション、便利な会議',
         meet_second: '多様な会議ニーズに対応する各種アプリケーション機能',
         system: 'デュアルシステム、複数行のタスクがスタックしない',
         system_second: 'Dual Android / liunx + Winデュアルシステム',
         control: 'リモートで、全てをコントロール'

      },
      ebook: {
         ebook: '电子书'
      },
      speaker: {
         speaker: 'スピーカー台',
         emeet: 'Emeet',
         communicate: '新しい働き方を始めよう！',
         lang: 'オートゲインコントロール機能も付きのため、自動的に検知し、ある程度に音量を増幅し、発言内容がより明確的に伝えます。',
         m220: 'M220 Lite',
         wireless: 'ワイヤレスアダプター',
         noDriver: 'ドライバー必要なし、プラグアンドプレイ',
         people: '3-16人',
         metting: 'ビジネス会議に欠かせない',
         support: '支持Skype for Business\\Teams\\Zoom',
         noVoice: 'AIノイズキャンセリング',
         mike: '高性能マイク',
         faceCommunicate: '快適な双方向会話が可能になりました',
         max: 'M2 Max',
         bluetooth: "Bluetooth同時接続2台まで可能",
         switch: '高い互換性・シームレスな接続性',
         clear: 'プロ用高性能マイク',
         power: '5W出力スピーカー',
         size: 'サイズ（mm）',
         battery: 'バッテリー容量',
         distance: 'Bluetooth最大距離',
         // connect:'Bluetoothデバイス同時',
         output: '接続方法',
         Capacity: '対応人数',
         BatteryLife: 'バッテリー航続',
         twospeak: '双方向会话機能',
         audio: 'オーディオミキサー機能',
         doa: '位置検出機能&LED指示',
         cascade: '連結機能',
         cascades: '（連結ケ－プル別売）',
         kg: '正味重量 (g)',
         view: '視野',
         vedio: '動画形式',
         mikes: 'マイクロフォン',
         trumpet: 'トランペット',
         noise: 'インテリジェントなノイズリダクション',
         echo: 'エコー・キャンセリング',
         light: '自動ローライト補正',
         tripod: '三脚架',
         compatible: '互換性',
         add: 'VoiceIA ® 音声アルゴリズム',
         connect: 'EMEETカスケードラインを接続して実現',
         focus_a: '固定焦点:f=4.6mm, FOV: 90',
         focus_b: '自動フォーカス:FOV: 90°',
         focus_c: '固定焦点:f=4.6mm, FOV:90°',
         focus_d: 'AIスマート人型追跡 FOV: 90°',
         numberpeople_five: '5名様まで',
         numberpeople_six: '6名様まで',
         numberpeople_eight: '8名様まで',
         numberpeople_sixteen: '16名様まで',
         interface_a: 'USB',
         interface_b: 'BT, USB, AUX',
         interface_c: 'BT, USB, AUX',
         interface_d: 'BT, USB, AUX',
         interface_e: 'BT, USB, AUX',
         interface_f: 'BT, USB',

      }
   },
   consult: {
      title: 'お問い合わせ',
      contact: '迅速に連絡して、協力をより簡単にする',
      ask: '次のフォームに入力して製品資料を取得します。',
      setion_a: '弊社サービスに関するお問合せフォームと資料請求をお受けしています。',
      setion_b: '下記の必要事項を入力して「プライバシーポリシー」に同意の上、送信ボタンをクリックしてください。',
      setion_c: '必須の項目(*) は必ずご記入ください。',
      setion_d: 'ご記入のメールアドレスに返信いたしますので、正確にご記入いただきますようお願い致します。',
      setion_e: 'お急ぎの場合は、直接メールinfo@enway.co.jpまでお送りください、ご提供いただいた個人情報を適切に管理いたします。',
      setion_f: '特段の事情がない限り本人の承諾なく第三者に開示、提供することはございません。',
      setion_g: '事前に「プライバシーポリシー」をお読みいただき、ご同意の上送信ください。',
      companyName: '企業名',
      name: 'お名前',
      number: '電話番号',
      email: 'メールアドレス',
      content: '内容',
      message: 'メッセージ',
      agree: ' に同意する',
      privacy: ' プライバシーポリシー',
      must: '* 必须项目',
      type: '協力型です',
      consultation: 'コンサルティング製品です',
      regionalAgent: '地域エージェントです',
      cooperation: '市場協力です',
      error: {
         notNull_company: '会社名を空にすることはできません',
         notNull_name: '名前を空にすることはできません',
         notNull_number: '電話番号を空にすることはできません',
         ture_number: '正しい電話番号の形式を入力してください',
         notNull_email: '電子メールを空にすることはできません',
         ture_email: '正しいメールフォーマットを入力してください',
         notNull_content: '内容の選択をお願いします。'
      },
      submit: '送信',
      submitSuccess: '情報が正常に送信されました'
   },
   Information: {
      title: '企業情報',
      setiona_title: '企　業　名:',
      setion_a: '株式会社エンウェイ',
      setionb_title: '設　立　日:',
      setion_b: '2015年(平成27年)5月15日',
      setionc_title: '所　在　地:',
      setion_c: '大森オフィス  〒143-0016 東京都大田区大森北3-1-2 KKSビル4F',
      setione_title: '取 引 先 銀 行:',
      setion_e: '三菱UFJ銀行/みずほ銀行',
      setionf_title: '代 表 取 締 役:',
      setion_f: '劉　志鎖',
      setiong_title: 'TEL:',
      setion_g: '03 6404 6200',
      setion_h: 'Google Mapで見る',
      Mission: { title: 'Mission', content: 'テクノロジーとデザインと情熱で「夢」を「形」に変えていくどんなに良い発想や構想も「形」にしなければ意味がありません。', pcdetails: 'どんなに良い発想や構想も「形」にしなければ意味がありません。 私たちのミッションは、事業やプロダクトへの熱い想いを最高の形で現実世界で実現させていくことです。 常にクライアントと同じ立場で、熱い想いを共有し、ともに悩みともに喜び合えるパートナーでありたい。 そんな考えで最高のテクノロジーとデザインでサービス開発の支援等を行っております。' },
      Vision: { title: 'Vision', content: '人が中心で、もの、ことの「デジタル（仮想）空間とフィジカル（現実）空間の高度な融合」を実現', pcdetails: '私たちは、単一のプロダクトのみではなく「アイデアをカタチにし続けるエコシステム」を目指しています。 「課題を整理するシンクタンク」と「革新的な科学技術を生み出す研究所」の間の溝を埋める「エンジニアリングのプロフェッショナル」として新しい価値を創造します。' },

   },
   Contact: {
      Contents: {
         cleaningRobot: 'クリーニングロボット',
         MAXHUB: 'MAXHUB',
         eMeet: 'eMeet',
         robot: 'ロボット',
         car: '車載',
         others: 'その他'
      },
      Tip: {
         title: "弊社サービスに関するお問合せは、下記フォームからお願いいたします。",
         setion_a: "必要事項をご記入いただき「プライバシーポリシー」に同意のうえ、送信ボタンをクリックしてください。",
         setion_b: "ご記入いただいたメールアドレスに返信いたしますので、お間違いのないようご記入お願いいたします。",
         setion_c: "お急ぎの場合は、直接メール info@enway.co.jp までご連絡ください。",
         setion_d: "ご提供いただいた個人情報は適切に管理いたします。",
         setion_e: "特段の事情がない限り本人の承諾なく第三者に開示、提供することはございません。",
         setion_f: "事前に「プライバシーポリシー」をお読みいただき、ご同意のうえ送信ください。",
      },
      contact: ' お問い合わせ'
   },
   alert: {
      here: 'すでにこのページにあります'
   },
   privacyPolicy: {
      title: '個人情報保護方針',
      content: '株式会社Enway（以下、当社）は、個人情報を適切に管理することを社会的責務であると認識し、以下のとおり個人情報保護方針を定めることにより、 全従業員に個人情報保護の重要性の認識と取組みを周知徹底させ、個人情報の保護に努めます。',
      title_a: '個人情報の利用目的',
      content_a: '当社がお客様からお預かりした個人情報は、当社からお客様へのご連絡やご質問に対する回答、製品やサービスのご提供及びご案内、営業活動等における、電子メールや資料のご送付のため、また、その他正当な目的のために利用致します。',
      title_b: '個人情報の管理',
      content_b: '当社は、お客様の個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。',
      title_c: '個人情報の委託',
      content_c: '当社は、上記に明示した利用目的を達成するため、ご提供いただいた個人情報の取り扱いを業務委託先又は提携先に委託する場合があります。個人情報の取扱いを外部に委託する場合は、個人情報を適正に取り扱っていると認められる者を選定し、選定された第三者による当該個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。',
      title_d: '個人情報の第三者提供',
      content_d: '当社は、個人情報保護法等の法令に定めのある場合や特段の事情を除き、個人情報をあらかじめご本人の同意を得ることなく、第三者に提供致しません。',
      title_e: '個人情報の開示・訂正等',
      content_e1: '当社は、お客様から個人情報に関して、開示、訂正、削除を求められた場合、当該ご請求が当社の業務に著しい支障をきたす恐れがある場合を除き、お客様ご本人によるものであることが確認できた場合に限り、速やかに、お客様の個人情報を開示、訂正、削除致します。',
      content_e2: 'お客様からご提供頂いた個人情報に関して、開示、訂正、削除をご要望される場合は、お問い合わせ先窓口までご請求ください。',
      title_f: '法令、規範の遵守と見直し',
      content_f: '当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、以上の方針内容を適宜見直し、その改善に努めます。'
   },
   rules: {
      title: '利用規約',
      content: '本ウェブサイトは、株式会社enway（以下「当社」といいます）およびその関係会社により運営されています。本ウェブサイトを利用することをもって、本利用条件に同意したものとみなされます。なお、本利用条件は変更される場合があります。',
      title_a: '免責事項',
      content_a: '当社およびその関係会社は本ウェブサイトに掲載されている内容について、その正確性、有用性、確実性、安全性、その他いかなる保証もするものでなく、本ウェブサイトの利用によって何らかの損害が生じた場合でも、当社は一切の責任を負いません。本ウェブサイトの掲載内容は予告なしに変更または削除される場合があります。',
      title_b1: '知的財産権',
      title_b2: '著作権',
      content_b: '本ウェブサイト上に掲載されている文書、グラフィックス、ロゴ、デザイン、写真等のコンテンツの著作権は、当社またはその作成者に帰属します。私的使用その他法律で明示的に認められる場合を除き、当社の事前の許可なくこれらのコンテンツを複製、転用、改変等することを禁止します。',
      title_c: '商標権',
      content_c: '本ウェブサイト上で使用されている当社または当社の関係会社の商品・サービス名称等は、それぞれ当社もしくは当社の関係会社の商標または登録商標です。その他の会社名・商品・サービス名称等は各社の商号、商標または登録商標です。',
      title_d: '製品仕様について',
      content_d: '本ウェブサイトに掲載されている製品の仕様は予告なく変更する場合があります。あらかじめご了承ください。',
   },
   deliveryx1: {
      header_content: 'テクノロジーとアフォーダビリティの融合',
      banner: {
         title: 'X1、テクノロジーとアフォーダブルの出会い',
         content: 'Delivery X1 は、自律型配送ロボットです。人手不足と人件費の高騰が最大の課題であるホスピタリティ業界において、費用対効果の高いスマートデリバリーソリューションを提供します。X1は、配送ラインの最初の製品であり、食品、飲料、または様々な貨物の基本的なポイント・ツー・ポイントの自律的配送を実行します。この自律型配送ロボットは、Gausiumの業界をリードするナビゲーション技術と最先端のセンサーシステムによって、高度な位置特定と環境認識能力を備えています。また、高度な衝撃緩和メカニズムを採用し、安定した、こぼれない配送プロセスを提供します。',
         end: '受賞：'
      },
      details: {
         title_a: 'ホスピタリティ＆エンターテインメント',
         title_b: 'ヘルスケア',
         title_c: 'オフィスビル',
         content_title: {
            title_a: 'ホスピタリティ',
            title_b: 'ヘルスケア',
            title_c: '事業所'
         },
         content: {
            title: '主な特徴',
            boxA: {
               title: 'デスクトップアプリカスタマイズ',
               content: 'QRコードを読み取るだけで簡単にセットアップができ、現場のインテリアを守りながら展開時間を75％短縮することが可能'
            },
            boxB: {
               title: 'スマートトレー（X1 Pro）',
               content: '重量センサーとLED表示灯を搭載し、負荷の状態を知らせ、負荷が空になったら自動的に退出します'
            },
            boxC: {
               title: '業界最高水準の衝撃緩和性能',
               content: '自動車レベルの独立懸架式シャーシを採用し、最高の衝撃緩和効果を発揮して、こぼれない安定した搬入を実現'
            },
            boxD: {
               title: '狭い通路を移動する',
               content: '高精度センサーで65cm幅の通路も楽々通過'
            },
            boxE: {
               title: '高性能バッテリー',
               content: 'リン酸リチウム電池の採用により、一般的な三元系リチウム電池に比べて熱安定性が高く、耐久性が6倍以上。'
            },
            boxF: {
               title: 'マルチロボットコラボレーション',
               content: '大規模サイトにおけるロボット間の相互通信による協調作業を実現することができる'
            }
         }
      },
      intro: {
         boxA: { header: '短期的なROI', title: '生産性を上げ、コストを下げる', content: '最大30kgの耐荷重があり、Delivery X1 、一度に複数のテーブルにサービスを提供することができます。また、大容量のLFPバッテリーに支えられた長い稼働時間を持ち、最も効率的な経路を計算することでエネルギー消費を抑えます。さらに、X1の手頃な価格は、短期間での投資回収を約束し、運用コストの削減に貢献します。' },
         boxA_list: {
            one: '最大30kgの耐荷重',
            two: '14～20時間の稼働時間', three: 'キーンプライス'
         },
         boxB: { header: 'デリバリー・エフォートレス', title: 'サービスプロセスの簡素化', content: 'Delivery X1 は、手間をかけずに展開できるのが特徴です。位置決め補助のためのマーカーをスキャンする必要がありません。自律型配送ロボットのトレイには、重量センサーとLEDインジケーターランプが搭載されており、荷物の状態を感知して信号を送ります。顧客によって荷物が空になると、ロボットは自動的に次の作業に向けて出発します。オプションの人検知用AIカメラにより、X1 Proは人と積極的に交流し、お客様のビジネスへの集客を支援します。' },
         boxB_list: {
            one: 'マーカーレス展開',
            two: 'スマートな負荷の認識と表示',
            three: '遠隔操作用モバイルアプリ',
            four: '人間検出とインタラクション（オプション）'
         },
         boxC: { header: '障害物回避・衝撃緩和', title: 'ぶつからない、こぼれない', content: 'Delivery X1 のナビゲーションアルゴリズムは、LiDARと3D深度カメラの融合に基づいており、高度な環境認識とスマートな障害物回避を提供します。自動車レベルの独立懸架式シャーシは、優れた衝撃緩和効果を生み出し、飲み物や液体料理をスムーズかつ安定的に提供することができます。' },
         boxC_list: {
            one: 'スマートな障害物回避',
            two: '独立懸架式シャシー'
         },
      },
      footer:{
         title:'DELIVERY X1/X1 Pro 仕様',
         list:{
            title_a:'商品のサイズ:',
            title_b:'バッテリー容量:',
            title_c:'連続稼働時間:',
            title_d:'最大移動速度:',
            title_e:'登攀勾配:',
            content_a:'515（L）* 523（W）* 1286（H）mm (X1)',
            content_aa:'515（L）* 531（W）* 1310（H）mm (X1 Pro)',
            content_b:'20 Ah',
            content_c:'14 - 20h',
            content_d:'1.2m/s',
            content_e:'5°',
         }
      }
   }
}
