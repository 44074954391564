<template>
  <div class="footer">
    <div class="container">
      <div class="enway">
        <img src="../../assets/footer/enway.png" alt="" />
      </div>
      <div class="center pc">
        <ul>
          <h5>{{ this.$t("nav.cleaningRobot") }}</h5>
          <li class="click" @click="$router.push('/phantas')">Phantas</li>
          <li class="click" @click="$router.push('/vacuum40')">Vacuum 40</li>
          <li class="click" @click="$router.push('/scrubber50')">
            Scrubber 50
          </li>
          <li class="click" @click="$router.push('/scrubber75')">
            Scrubber 75
          </li>
          <li class="click" @click="$router.push('/sweeper111')">
            Sweeper 111
          </li>
        </ul>
        <ul>
          <h5>{{ this.$t('nav.delivery') }}</h5>
          <li class="click" @click="$router.push('/deliveryx1')">Delivery X1</li>
        </ul>
        <ul>
          <h5>{{ this.$t("nav.smartHome") }}</h5>
          <li class="click" @click="$router.push('/tv')">MAXHUB</li>
          <li class="click" @click="$router.push('/speaker')">
            eMeet Meeting Capsule
          </li>
        </ul>
        <ul>
          <h5>{{ this.$t("nav.robot") }}</h5>
          <li class="click" @click="$router.push('/robotDog')">
            {{ this.$t("nav.robot") }}
          </li>
        </ul>
        <ul>
          <h5>{{ this.$t("nav.car") }}</h5>
          <li class="click" @click="$router.push('/car')">
            {{ this.$t("nav.car") }}
          </li>
        </ul>
        <ul>
          <h5>{{ this.$t("nav.news") }}</h5>
          <li class="click" @click="$router.push('/news')">
            {{ this.$t("nav.news") }}
          </li>
        </ul>
        <ul>
          <h5>{{ this.$t("header.information") }}</h5>
          <li class="click" @click="$router.push('/information')">
            {{ this.$t("header.information") }}
          </li>
          <li class="click" @click="$router.push('/contact')">
            {{ this.$t("header.inquiry") }}
          </li>
        </ul>
        <ul>
          <h5>{{ this.$t("header.inquiry") }}</h5>
          <li class="click" @click="$router.push('/information')">
            03 6404 6200
          </li>
          <li class="setion click" @click="$router.push('/information')">
            {{ this.$t("Information.setion_c") }}
          </li>
        </ul>
      </div>
      <div class="center mobile">
        <ul>
          <h4>TEL</h4>
          <li class="click">03 6404 6200</li>
        </ul>
        <ul>
          <h4>{{ this.$t("footer.location") }}</h4>
          <li class="click">
            {{ this.$t("Information.setion_c") }}
          </li>
        </ul>
      </div>
      <div class="bottom pc">
        © 2023 Enway Corporation. All Rights Reserved.
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span
          class="privacyPolicy click"
          @click="$router.push('/privacyPolicy')"
          >{{ this.$t("footer.privacyPolicy") }}</span
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="rules click" @click="$router.push('/rules')">{{
          this.$t("footer.rules")
        }}</span>
      </div>
      <div class="bottom mobile">
        <p class="trademark">© 2023 Enway Corporation. All Rights Reserved.</p>
        <p>
          <span
            class="privacyPolicy click"
            @click="
              $route.fullPath === '/privacyPolicy'
                ? alertHandler(this.$t('alert.here'))
                : $router.push('/privacyPolicy')
            "
            >{{ this.$t("footer.privacyPolicy") }}</span
          >&nbsp;&nbsp;&nbsp;&nbsp;
          <span
            class="rules click"
            @click="
              $route.fullPath === '/rules'
                ? alertHandler('alert.here')
                : $router.push('/rules')
            "
            >{{ this.$t("footer.rules") }}</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";

export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    alertHandler(text) {
      Message.error(text);
    },
  },
};
</script>

<style scoped lang='less'>
.footer {
  // height: 540px;
  background-color: #000;
  display: flex;
  justify-content: center;
  .container {
    width: 1200px;
    height: 100%;
    .enway {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 122px;
      border-bottom: 1px solid #4a4a4a;
    }
    .center {
      margin-bottom: 69px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #b7b7b7;
      text-align: left;
      h5 {
        padding: 49px 0 40px 0;
        font-size: 16px;
        color: #ffffff;
      }
      li {
        padding-bottom: 20px;
      }
      .setion {
        width: 128px;
      }
    }
    .center.mobile {
      margin-bottom: 0 !important;
      padding: 10px 0;
      display: none;
      border-bottom: 1px solid #444;
      h4 {
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
      }
      li {
        font-size: 12px;
        line-height: 20px;
        padding-bottom: 5px !important;
      }
    }
    .bottom {
      text-align: center;
      padding: 14px 0;
      font-size: 12px;
      color: #dcdee2;
    }
    .bottom.mobile {
      display: none;
      text-align: left;
      line-height: 20px;
      .trademark {
        color: #999;
      }
    }
  }
  // 当屏幕宽度小于860px时
  @media screen and (max-width: 860px) {
    .container {
      padding: 0 18px;
    }
    .center.pc {
      display: none;
    }
    .center.mobile {
      display: block !important;
    }
    .enway {
      height: 60px !important;
    }
    .bottom.pc {
      display: none;
    }
    .bottom.mobile {
      display: block !important;
    }
  }
}
</style>
