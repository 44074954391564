export default {
   MAXHUB: {
      HighQuality: '高质量的图像，超大的屏幕， 书写手感顺滑。<br/>以及，与多个设备进行互动连接。<br/>会议开始变得栩栩如生，相当于拥有了一个智慧大脑。<br/>MAXHUB激发了前所未有的想法。<br/>现在，这一个简单的面板给办公室带来了改革与创新。',
      MmUltraThin: '17.8毫米超薄',
      PixelHDR: '4800万像素HDR相机',
      ANewSeam: '新型缝线扬声器',
      boxA: {
         title: "桌面应用程序定制",
         content: '可以自定义桌面应用程序菜单。',
      },
      boxB: {
         title: 'Wi-Fi屏蔽模式下的连接保护',
         content: '作为热点连接到MAXHUB的设备 防止连接公司内部互联网 因为可以安全方面也放心。',
      },
      boxC: {
         title: '最多6个屏幕的同时投影',
         content: '因为可以从多个设备同时投影到最多6个画面,所以没有切换画面的工夫。',
      },
      boxD: {
         title: '多媒体导入(图像、视频、PDF、PPT)',
         content: '不仅可以插入图片，还可以插入视频、PDF、PowerPoint※1和其他多文件到白板中。<br/><br/>※1需要另外安装Microsoft office。',
      },
      boxE: {
         title: '用二维码传输文件速度',
         content: '通过用手边的设备读取QR码,可以迅速转发到MAXHUB。',
      },
      boxF: {
         title: '备注传送功能',
         content: '通过扫描二维码,可以将备注从远程传输到MAXHUB。',
      },
      TheScreen: '画面比率变高※2、超薄型设计。光学焊接技术可最大限度地减少视差,实现清晰的图像质量,体验前所未有的沉浸感。<br/><br/>4K UHD显示器｜PCAP触摸技术｜低至17.8mm｜光学键合｜防眩光玻璃。<br/><br/>※2与MAXHUB第三代进行比较。',
      ForPrivacy: '为了隐私和性能,最大采用16千兆字节DDR4-4800万像素翻转相机,启动网络会议应用程序后相机会自动上升。<br/><br/>自动放大扬声器|更高的SNR|仅在需要时翻到前面。',
      HighPower: '40W※3的高输出扬声器和进一步提高了的音频算法,实现了专业的录音室质量一样的音频体验。<br/><br/>2.1立体声|40W强力扬声器。<br/><br/>※3扬声器输出为2×10瓦+20瓦(低音炮）。',
      EquippedWith: '6个阵列麦克风,8米的集音范围,定位技术,从角落和前方都能准确地集音。根据发言者和麦克风的距离,把扬声器的音量调整到适当的水平的AGC功能※4也装载,真实地再现会议参加者的声音。<br/><br/>6元件麦克风阵列|高达8米的语音拾音器|语音本地化|自动增益|噪声消除。<br/><br/>※4AGC(自动增益控制）。',
      FeaturesAnd: '以只有数字才有的功能和书写舒适,实现舒适的操作性,无线转换器,专用应用程序「MAXHUB Share」,Type-C,X-link等,用各种各样的连接方式简单快速地画面共享可能。双向写操作可实时、准确地沟通。<br/><br/>MAXHUB无线加密狗| MAXHUB共享|C型端口|X-link。',
      PlugableDesign: '可插入式设计可帮助您轻松维护和升级所需的内容,因为它配备了可扩展性和兼容性良好的Windows,所以您可以从当前的内部系统中轻松使用。<br/><br/>Windows 10',
      ImageSet: '产品安装映像',
      ConferenceRoomMiddle: '中会议室',
      TheConference: '对于需要具体讨论、明确决策的会议，中会议室非常适合。',
      ConferenceRoomHigh: '大会议室',
      InTheConference: '大会议室将聚集大量相关人员进行发表、小组报告、谈判等。<br/><br/>使用MAXHUB,可以简单地分享想法,与不在场的人也能很容易地联系起来。',
      RecommendedSize: '建议的大小',
   },
   EMEET: {
      EmetOptimized: 'eMeet优化的360度音频和视频覆盖',
      TheExclusiveAI: '独占AI算法为了响应声音,完美地捕捉并呈现了在显示器上活跃的说话者的脸部，为整个团队提供了更有效的交流。',
      ToSee: '要看整个房间',
      The360Degree: '360°会议摄像头捕捉4K分辨率下半径13英尺(4m)以内的所有细节,输出HD1080P图像,无死角清晰覆盖整个会议室。',
      SoundClearly: '清楚地听到',
      SmartOmnidirectional: '8个智能无定向麦克风从半径5.5米(18英尺)以内的任何角度拾取声音,并将声音质量提升到下一级。',
      ListenToAll: '听所有的音节',
      The10W90dB: '10瓦/90分贝的扬声器可以让Hi-Fi级的声音更大更清晰,细节也更清晰。',
      originalCreation: '独创的悬置型腔设计可以避免振动噪音，因此麦克风和扬声器都能完美发挥作用。',
      MaximizingPerformance: '最大化性能 优化工作效率',
      BestProfessional: '最好的专业免提电话,360°拾音器定位,带环形灯,48kHz采样率,独特的DSP算法VoiceIA®、首个浮动麦克风腔体设计,高性能扬声器，非常易于使用，适合中大型会议室。',
   },
   home: {
      AutoSpot: '自动巡检清洁',
      QuadruplEefficiency: '提升4倍效率',
      PhantasExplores: 'Gausium突破性的巡检清洁模式 - 自动扫描地板清洁度- 自动去往污渍所在处定点清洁- 效率提升高达4倍，大大降低水电消耗',
      SeeMorel: '了解更多 >',
      RobotWith: '清扫吸尘',
      AnExper: '清洗推尘',
      CleaningOil: '清洗去油污',
      OutdoorCleaning: '室外清扫',
      OtherAreas: '其他领域',
      HighQuality: '高画质，超大画面。顺畅的书写心情。',
      Degree: '360度旋转摄像头',
      SpeechRecognition: '语音识别、人脸识别、手势识别',
      InVehicle: '车载',
      FullScreen: '全面屏幕，前面展望',
   },
   phantas: {
      SmartObstacle: '智能避障',
      PhantasDetects: '时刻检测动动态和静态障碍物；生成防撞路径，实时智能避障；深度学习算法驱动，无比强大障碍识别。',
      OneStop: '一站式自主服务',
      WhenCombined: 'Phantas 与一个充电底座配对，以尽量减少操作过程中的人为干预。它能够通过自主与工作站连接来进行自我服务。可选的水箱手推车可以为您节省加水的精力。',
      AutoSpot: '自动巡检清洁',
      QuadrupleEfficiency: '提升4倍效率',
      PhantasExplores: 'Gausium突破性的巡检清洁模式- 自动扫描地板清洁度- 自动去往污渍所在处定点清洁- 效率提升高达4倍,大大降低水电消耗',
      PhantasS1: 'Phantas S1 详细参数',
      ProductSize: '产品尺寸：',
      Products: '产品净重：',
      SensorConfiguration: '传感器配置：',
      KiyoaraiWidth: '清洁效率：',
      use: '洗地、清扫、尘推、地毯吸尘',
      pleonasm_title: 'Gaussian Robotics「扫地机器人」「配餐机器人」× ENWAY',
      pleonasm_content: '2022年，我司与高仙机器人公司达成业务合作、开始引进机器人并参与了「扫地机器人」的系统开发工作。<br/><br/>2023年，我司开始正式面向日本市场提供高仙机器人公司的「扫地机器人」和「配餐机器人」。'
   },
   vacuum40: {
      vacuum40: '商用吸尘机器人',
      ItIsCompatible: '兼容室内硬质地面和地毯，具备吸尘、清扫、推尘等多种功能的清洁机器人，广泛适用于写字楼、酒店、展厅、会议中心、学校、机场等场景',
      ThoroughCleaning: '彻底的清洗和空气清洁',
      EachFunction: '一机多用： 清扫、吸尘、推尘一体化<br/>软硬地面兼容：大理石、水磨石、瓷砖、地毯 <br/>地毯养护：分层清洁技术，羊毛地毯养护效果佳 <br/>雾化消杀： 超声波雾化消毒，除菌效率高<br/>全程无人化：自主充电、过闸机、坐电梯 <br/>智能化管理：支持任务排班、定时任务、远程运维 <br/>数字化运营：精细化、可视化数据管理',
      SimplifyingWorkflow: '简化工作流',
      Vacuum40Can: 'Vacuum40可以让清洁变得简单,几乎不用麻烦人类的手.因为感知环境的变化,实时更新地图变更路线,所以不需要帮助你抛锚的地方。如果使用可选的充电坞,机器人也可以自己充电。此外,还可以通过Gausium移动应用程序进行远程访问,可以从任何地方监视和控制清扫作业。',
      Vacuum40Specifications: 'Vacuum40详细参数',
      ProductSize: '产品尺寸：',
      BatteryCapacity: '电池容量：',
      ContinuousUptime: '连续工作时间：',
      KiyoaraiWidth: '主要画笔规格：',
      SensorConfiguration: '传感器配置：',
      DeviceDisposition: '激光雷达，深度摄像头，彩色摄像头，防跌落，防跌落'
   },
   scrubber50: {
      scrubber50: '商用清洁机器人',
      RobotsForCleaning: '室内硬地板清洗、清洁和除尘机器人适用于广泛的场景，包括购物中心、超市、医院、办公楼、酒店、交通枢纽、工业区和学校。',
      Scrubber50Specification: 'Scrubber50规格',
      ProductSize: '商品尺寸：',
      BatteryCapacity: '电池容量：',
      ContinuousUptime: '连续工作时间：',
      KiyoaraiWidth: '清洗宽度：',
      SensorConfiguration: '传感器配置：',
      ClimbingSlope: '攀登坡度：',
      dispose: '激光雷达*1，深度摄像头*3，双侧深度摄像头（选配），顶视摄像头（选配），电子防撞条，防跌落，负压传感器'
   },
   scrubber75: {
      scrubber75: '商用清洁机器人',
      ThisCleaning: '一种室内外中型及大型场景的清洗机器人，适用于广场、机场、车站、停车场、工厂、仓库、超市、学校等室内外各种场合。具有清扫地板、去除油污、除尘、防止异物堵塞等功能。',
      Scrubber75Specification: 'Scrubber75规格',
      ProductSize: '商品尺寸：',
      BatteryCapacity: '电池容量：',
      ContinuousUptime: '连续工作时间：',
      KiyoaraiWidth: '清洗宽度：',
      SensorConfiguration: '传感器配置：',
      configuration: '3D激光雷达，深度摄像头，2D激光雷达、毫米波雷达，电子防撞条'
   },
   sweeper111: {
      sweeper111: '商用清洁机器人',
      RobotsForCleaning: '室内硬地板清洗、清洁和除尘机器人适用于广泛的场景，包括购物中心、超市、医院、办公楼、酒店、交通枢纽、工业区和学校',
      Sweeper111Specification: 'Sweeper111规格',
      ProductSize: '商品尺寸：',
      BatteryCapacity: '电池容量：',
      ContinuousUptime: '连续工作时间：',
      KiyoaraiWidth: '清洗宽度：',
      TurningAngle: '转向角度：',
   },
   news: {
      newsCenter: '新闻中心',
      learnMore: '了解更多',
      noData: '当前没有数据',
      bannerTitle: '开始全面向日本提供高仙机器人公司的 "清洁机器人 "和 "食品输送机器人"',
      bannerContent: '2023年，我司开始正式向日本提供高仙机器人公司的”清洁机器人“和”餐饮机器人“。2022年，我司与高仙机器人公司达成业务合作，并在去年开始引进机器人。 我司还参与了 "清洁机器人 "的系统开发。'
   },
   header: {
      information: '公司介绍',
      inquiry: '联系我们',
   },
   footer: {
      privacyPolicy: '隐私政策',
      rules: '协议规则',
      directory: '目录',
      privacyClause: '隐私政策使用条款',
      location: '所在地'
   },
   nav: {
      car: '车载',
      phone: '手机',
      robot: '机器人',
      smartHome: '智能家居',
      cleaningRobot: '清洁机器人',
      news: '新闻',
      gohome: '回到主页',
      delivery:'配餐机器人'
   },
   car: {
      car: '车载',
      FullScreen: '全面屏',
      FullView: '全视野',
      math: '数字化仪表',
      adas: 'ADAS数字',
      safe: '安全驾驶辅助系统',
      system: '系统架构',
      navigation: '智能导航，精准又便利'

   },
   phone: {
      phone: '手机',
      second: '0.1秒抓拍',
      noUse: '无需任何按键',
      ai: 'AI 降噪',
      lowLight: '弱光环境',
      fillLight: '自动补光',
      beauty: '美颜功能',
      tracking: '实时追踪功能',
      focus: '主角即使焦点'
   },
   robot: {
      robot: '机器人',
      math: 'AI 算法',
      identify: '语音识别，人脸识别，手势识别',
      foot: '高自由度四足机器人',
      eye: '视觉识别',
      gesture: '手势识别、人脸跟踪、视觉编程环境',
      OpenSource: '开源',
      any: '定制任意步态算法ー踱步，跳跃，小跑',
      person: '私人订制',
      imagine: '无限想象空间带给您独一无二的设计',
      robotDog: 'Mini Tits：开源，ROS 机器狗套件'
   },
   smartHome: {
      tv: {
         tv: '电视',
         title: '运筹帷幄之间',
         title_second: '决胜千里之外',
         voice: '智能声控，坐不离席',
         voice_second: '声源定位 , 回声消除 , 智能降噪',
         face: '智能人脸追踪',
         face_second: '控制摄像头追踪人脸，实现智能控制和追踪',
         meet: '多元应用，便捷会议',
         meet_second: '多款应用功能，满足多样化的会议需求',
         system: '双系统，多线任务不卡顿',
         system_second: '双 Android/liunx+ Win 双系统',
         control: '远距遥控，掌控全局'

      },
      ebook: {
         ebook: '电子书'
      },
      speaker: {
         speaker: '音箱',
         emeet: 'Emeet',
         communicate: '为高效沟通而生',
         lang: '语音算法技术，可提供超清晰语音',
         m220: 'M220 Lite',
         wireless: '无线适配器',
         noDriver: '无需驱动程序，即插即用',
         people: '3-16人',
         metting: '多人会议',
         support: '支持Skype for Business\\Teams\\Zoom',
         noVoice: 'AI降噪',
         mike: '多个麦克风',
         faceCommunicate: '轻松享受如面对面交流版清晰音质',
         max: 'M2 Max',
         bluetooth: '连接2个蓝牙设备',
         switch: '可在多个设备间无感切换',
         clear: '超清输出麦克风',
         power: '大功率音箱',
         size: '尺寸(mm)',
         battery: '电池容量',
         distance: '蓝牙距离',
         // connect:'可同时连接蓝牙设备',
         output: '输出接口',
         Capacity: '可容纳人数',
         BatteryLife: '电池续航',
         twospeak: '双讲功能支持',
         audio: '音频特性',
         doa: 'DOA指示灯声音定位',
         cascade: '级联功能',
         cascades: '(级联线需额外购买)',
         kg: '净重 (g)',
         view: '视场角',
         vedio: '视频制式',
         mikes: '麦克风',
         trumpet: '喇叭',
         noise: '智能降噪',
         echo: '回声消除',
         light: '自动低光矫正',
         tripod: '三脚架',
         compatible: '兼容',
         add: '语音算法',
         connect: '通过连接EMEET级联线实现',
         focus_a: '定对焦:f=4.6mm, FOV: 90',
         focus_b: '自动对焦:FOV: 90°',
         focus_c: '固定对焦:f=4.6mm, FOV:90°',
         focus_d: 'AI智能人形追踪 FOV: 90°',
         numberpeople_five: '多达5人',
         numberpeople_six: '多达6人',
         numberpeople_eight: '多达8人',
         numberpeople_sixteen: '多达16人',
         interface_a: 'USB',
         interface_b: 'BT, USB, AUX',
         interface_c: 'BT, USB, AUX,无线适配器',
         interface_d: 'BT, USB, AUX,无线适配器',
         interface_e: 'BT, USB, AUX,无线适配器',
         interface_f: 'BT, USB,无线适配器'
      }
   },
   consult: {
      title: '联系我们',
      contact: '快速联系我们，让合作更简单',
      ask: '填写下方表单获取产品资料',
      setion_a: '我们接受有关我们的服务和材料请求的查询。',
      setion_b: '请在下方输入所需信息，同意“隐私政策”，然后点击提交按钮。',
      setion_c: '请务必填写必填项目（*）。',
      setion_d: '我们将回复您输入的电子邮件地址，因此请务必正确输入。',
      setion_e: '如果您有急事，请直接发送到我们的邮箱info@enway.co.jp，我们会妥善管理您提供的个人信息。',
      setion_f: '除特殊情况外，未经本人同意，我们不会向第三方披露或提供。',
      setion_g: '请提前阅读《隐私政策》并同意后发送。',
      companyName: '会社名',
      name: '姓名',
      number: '电话号码',
      email: '电子邮件地址',
      content: '内容',
      message: '信息',
      agree: '我同意',
      privacy: ' 隐私政策',
      must: '* 必须项目',
      type: '合作类型',
      consultation: '咨询产品',
      regionalAgent: '区域代理',
      cooperation: '市场合作',
      error: {
         notNull_company: '公司名不能为空',
         notNull_name: '姓名不能为空',
         notNull_number: '手机号不能为空',
         ture_number: '请输入正确手机号格式',
         notNull_email: '邮箱不能为空',
         ture_email: '请输入正确邮箱格式',
         notNull_content: '请选择内容'
      },
      submit: '发送',
      submitSuccess: '信息已提交成功'
   },
   Information: {
      title: '企业信息',
      setiona_title: '公 司 名 称:',
      setion_a: 'Enway公司',
      setionb_title: '成　立　日:',
      setion_b: '2015年(平成27年)5月15日',
      setionc_title: '所　在　地:',
      setion_c: '大森办公室 〒143-0016 东京都大田区大森北3-1-2KKS大厦4F',
      setione_title: '开　户　行:',
      setion_e: '三菱UFJ银行/瑞穗银行',
      setionf_title: '首 席 执 行 官:',
      setion_f: '劉　志鎖',
      setiong_title: 'TEL:',
      setion_g: '03 6404 6200',
      setion_h: '在谷歌地图上查看',
      Mission: { title: '使命', content: '用技术、设计和热情把“梦想”变成“形式”，再好的想法和构想如果不变成“形式”就没有意义。', pcdetails: '再好的想法和构想如果不“形”就没有意义。我们的任务是以最好的形式在现实世界中实现对事业和产品的热情。想成为经常与客户同样的立场，共享热的感情，一起烦恼一起喜悦互相的搭档。以那样的想法用最高的技术和设计进行着服务开发的支援等。' },
      Vision: { title: '理想', content: '以人为中心，实现事物的“数字（虚拟）空间和物理（现实）空间的高度融合”', pcdetails: '我们的目标不仅仅是单一的产品，而是“以想法为形式持续的生态系统”。作为填补“整理课题的智囊团”和“创造创新科学技术的研究所”之间的隔阂的“工程专家”创造新的价值。' },

   },
   Contact: {
      Contents: {
         cleaningRobot: '清洗机器人',
         MAXHUB: 'MAXHUB',
         eMeet: 'eMeet',
         robot: '机器人',
         car: '车载',
         others: '其他'
      },
      Tip: {
         title: "感谢您访问我们的网站。请通过下方表格进行我司服务的咨询。",
         setion_a: '请填写必填项，同意 "隐私声明 "并点击发送按钮。',
         setion_b: "我们将按照您提供的电子邮件地址进行回复，请确保您填写的信息正确无误。",
         setion_c: "有事请拨打我们的服务热线，或者发送邮件至info@enway.co.jp。",
         setion_d: "您提供的个人信息会受到严格保护。",
         setion_e: "在正常情况下，我们不会在未经您同意的情况下将您的个人信息披露或提供给第三方。",
         setion_f: "在发送您的信息之前，请阅读并同意“隐私声明”。",
      },
      contact: '联系我们'
   },
   alert: {
      here: 'すでにこのページにあります'
   },
   privacyPolicy: {
      title: '隐私声明',
      content: 'Enway公司（以下简称\'Enway\'）认识到，Enway有妥当保管个人信息的社会责任，并制定了以下隐私政策，以确保所有员工了解保护个人信息的重要性以及致力于保护个人信息。',
      title_a: '个人信息的使用目的',
      content_a: '我们从客户处获得的个人信息将用于与客户联系，回答客户的问题，提供并告知客户我们的产品和服务，在销售活动中向客户发送电子邮件和资料，以及其他合法目的。',
      title_b: '个人信息的管理',
      content_b: 'Enway采取了必要的措施使得客户个人信息的获取准确且及时，防止未经授权的访问、丢失、损坏、篡改或泄漏个人信息，如维护安全系统、制定管理制度和彻底的员工培训，实施安全措施，严格管理个人信息。',
      title_c: '个人信息的外包',
      content_c: '为了实现上述规定的使用目的，Enway可能将您提供的个人信息的处理外包给分包商或合作伙伴。 如果个人信息被外包给第三方，我们将选择一个能够正确处理个人信息的一方，并加以必要和适当的监督，以确保被选中的第三方安全地管理有关的个人信息。',
      title_d: '向第三方提供个人信息',
      content_d: '未经当事人事先同意，Enway不会将个人信息提供给第三方，除非是在《个人数据保护法》或其他法律法规要求或别的特殊情况下。',
      title_e: '个人信息的公开和更正等事项',
      content_e1: 'Enway在客户要求公开、修改、删除个人信息时，除该请求对Enway业务有严重影响的情况外，仅在确认是由客户本人提出的情况下，将迅速公开、修改、删除客户的个人信息。',
      content_e2: '关于客户提供的个人信息，如果希望公开、修改、删除的话，请联络咨询处窗口。',
      title_f: '对法律、法规和规范的遵守和审查',
      content_f: 'Enway将遵守适用于所掌握的个人信息的日本法律、法规和其他规范，并将对上述政策的内容进行适当的审查和改进。'
   },
   rules: {
      title: '使用条款',
      content: '本网站由Enway公司（以下简称"Enway"）及其相关公司运营。 通过使用本网站，视为同意了本使用条款。 另外，本使用条款有变更情况。',
      title_a: '免责声明',
      content_a: '本公司及其关联公司对本网站所刊登的内容，不作其准确性、有用性、可靠性、安全性及其他任何保证，对使用本网站引起的任何损失或损害不承担任何责任。本网站的内容如有更改或删除，不做另行通知。',
      title_b1: '知识产权',
      title_b2: '著作权',
      content_b: '本网站上的文件、图形、标志、设计、照片和其他内容的版权归Enway或其作者所有。除私人使用及其他法律明确承认的情况外，未经本公司事先许可，禁止复制、转用或更改这些内容。',
      title_c: '商标权',
      content_c: '本网站上使用的Enway或其关联公司的产品和服务名称分别为Enway或其关联公司的商标或注册商标。其他公司名称、产品和服务名称等是其各自公司的商号、商标或注册商标。',
      title_d: '产品规格',
      content_d: '本网站上的产品规格如有变化，恕不另行通知。请知悉。',
   },
   deliveryx1: {
      header_content: '技术领先的智能送餐机器人',
      banner: {
         title: '技术领先智能送餐机器人 X1',
         content:'Delivery X1 是一个自主送货机器人。它为劳动力短缺和劳动力成本增加是首要挑战的酒店业提供了一个具有成本效益的智能交付解决方案。作为配送线的第一款产品，X1可以执行基本的点对点自主配送食品、饮料或各种货物的任务。这款自主送货机器人具有先进的定位和环境感知能力，由Gausium行业领先的导航技术和尖端的传感器系统提供支持。它还采用了先进的减震机制，以提供一个稳定的、防溢出的交付过程。',
         end: '奖项：'
      },
      details: {
         title_a: '酒店餐饮',
         title_b: '医疗保健',
         title_c: '办公楼',
         content_title: {
            title_a: '酒店餐饮',
            title_b: '医疗保健',
            title_c: '写字楼'
         },
         content: {
            title: '功能亮点',
            boxA: {
               title: '无忧部署',
               content: '无需扫描二维码即可轻松部署，节省75%的部署时间，同时保持场地的内部环境。'
            },
            boxB: {
               title: '智能托盘（X1 Pro）',
               content: '配有重量传感器和LED指示灯，提示承载状态，并在托盘清空后自动驶离。'
            },
            boxC: {
               title: '行业领先的减震设计',
               content: '采用车规级独立悬挂底座，确保最佳减震效果，保证稳定、防泼溅的配送。'
            },
            boxD: {
               title: '可通过狭窄过道',
               content: '在高精度传感器的支持下，可轻松通过最小为65厘米的过道。'
            },
            boxE: {
               title: '高性能电池',
               content: '采用磷酸锂电池——热稳定性更高，比普通三元锂电池提高6倍续航时间。'
            },
            boxF: {
               title: '多机协作',
               content: '能够实现机器人之间的互联互通，可在大型现场协同工作。'
            }
         }
      },
      intro: {
         boxA: { header: '快速实现投资回报', title: '提高递送效率，降低成本', content: 'Delivery X1 的承重能力高达30公斤，可以一次服务多个餐位。大容量LFP电池，保障了超长运行时间；同时可计算最有效路径和节省能源消耗。实惠的价格可在短时间内降低运营成本，保证快速投资回报。' },
         boxA_list: {
            one: '高达30公斤的负载能力',
            two: '14-20小时的正常运行时间', three: '超高性价比'
         },
         boxB: { header: '让送餐毫不费力', title: '简化您的服务流程', content: 'Delivery X1 具有无忧的部署过程。它不需要扫描位置标记来协助定位。自主送货机器人的托盘配备了重量传感器和LED指示灯，可以感知和显示负载状态。当客户将货物清空后，机器人将自动离开，执行下一个任务。X1 Pro有一个可选的用于人类检测的AI摄像头，将主动与人互动，以帮助为您的业务吸引更多的流量。' },
         boxB_list: {
            one: '无码部署',
            two: '智能负载感知和指示',
            three: '可通过APP远程操控',
            four: '顾客识别和互动（可选）'
         },
         boxC: { header: '智能避障 超强减震', title: '平稳递送无倾洒', content: 'Delivery X1 的导航算法基于LiDAR和3D深度摄像头的耦合，提供高水平的环境感知和智能避障。车规级独立悬挂底座提供了超强的减震效果，提供平稳的配送体验。' },
         boxC_list: {
            one: '智能避障',
            two: '独立悬挂底座'
         },
      },
      footer:{
         title:'DELIVERY X1/X1Pro规格',
         list:{
            title_a:'商品的尺寸:',
            title_b:'电池容量:',
            title_c:'连续运行时间:',
            title_d:'最大移动速度:',
            title_e:'攀登坡度:',
            content_a:'515（L）* 523（W）* 1286（H）mm (X1)',
            content_aa:'515（L）* 531（W）* 1310（H）mm (X1 Pro)',
            content_b:'20 Ah',
            content_c:'14 - 20h',
            content_d:'1.2m/s',
            content_e:'5°',
         }
      }
   }
}
