// 自定义指令 v-image="图片地址"
export const image = {
  inserted: function (dom, options) {
    dom.src = dom.src || options.value
    dom.onerror = function () {
      dom.src = options.value
    }
  },
  // 父子组件vNodes 更新完毕
  componentUpdated: function (dom, options) {
    dom.src = dom.src || options.value
  }
}