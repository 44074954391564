import Vue from 'vue'
// 重置css样式
import 'normalize.css'
import 'flexible.js'
// 公共css
import './styles/base.css'
import './fonts/fonts.css'
// 引入VueRouter router路由文件
import router from '@/router'

// 自定义指令
import * as directives from '@/utils/directives'
Object.keys(directives).forEach((keys) => {
  Vue.directive(keys, directives[keys])
})
import i18n from "@/language/i18n";

// 引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';


Vue.config.productionTip = false
// 全局注册element-ui
Vue.use(ElementUI);
Vue.use(ElementUI), {
  i18n: (key, value) => i18n.vm._t(key, value)
};
Vue.use(i18n);
new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
