<template>
  <div
    id="app"
    :class="{hidden:isMobile}"
  >
    <!-- 顶部菜单栏 -->
    <div class="header">
      <NavBar class="navbar" />
    </div>
    <!-- 一级路由 -->
    <router-view />
    <!-- 底部商标 -->
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/footer";
import NavBar from "@/components/NavBar";
export default {
  components: {
    Footer,
    NavBar,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  created() {},
  mounted() {
    // 获取浏览器标识字符串
    var ua = navigator.userAgent;
    // 定义移动设备关键字数组
    var mobileKeys = ["Android", "iPhone", "iPad", "iPod", "Windows Phone"];
    // 遍历移动设备关键字数组，判断浏览器标识字符串是否包含其中之一
    for (var i = 0; i < mobileKeys.length; i++) {
      if (ua.indexOf(mobileKeys[i]) > -1) {
        // 如果包含，则说明是手机打开，将变量设置为true，并跳出循环
        this.isMobile = true;
        break;
      }
    }
  },
  methods: {},
};
</script>

<style scoped lang='less'>
.header {
  // height: 80px;
  background-color: #000;
  .navbar {
    max-width: 1200px;
    margin: 0 auto;
  }
}
#app {
  font-family: "Meiryooo" !important;
}
.hidden {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
.hidden::-webkit-scrollbar {
    width: 0;
  }
</style>
