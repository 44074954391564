export default {
   MAXHUB: {
      HighQuality: 'High quality, super large screen. Smooth writing experience.<br/>And it leads to bidirectional devices.<br/>The meeting moves vigorously. Start with an excellent brains.<br/>Think of the idea before, maxhub.<br/>This one panel makes innovation to the office now.',
      MmUltraThin: '17.8 mm ultra thin',
      PixelHDR: '4.8m pixel HDR camera',
      ANewSeam: 'A new seam speaker',
      boxA: {
         title: "Desktop app customization",
         content: 'Customize the desktop app menu',
      },
      boxB: {
         title: 'Connection protection in Wi Fi shield mode',
         content: 'Device connected to maxhub as hotspot,To prevent the Internet from connecting to the Internet,Security aspect is also safe',
      },
      boxC: {
         title: 'Simultaneous projection of up to six screens',
         content: 'It can project up to six screens simultaneously from multiple devices, so there is no time to switch between screens',
      },
      boxD: {
         title: 'Multimedia import(images, videos, PDFs, power points)',
         content: 'A multi file, such as video, PDF, power point<br/><br/>* you need to install one Microsoft office separately.',
      },
      boxE: {
         title: 'File speed transfer by QR code',
         content: 'You can quickly transfer the QR code to your device',
      },
      boxF: {
         title: 'Memo transfer function',
         content: 'By scanning QR code, you can transfer notes to maxhub from remote locations',
      },
      TheScreen: 'The screen ratio becomes high, and it becomes the ultrathin design. Optical bonding technology minimizes parallax, realizes image quality, and allows you to experience immersion.<br/><br/>4K UHD display,Down to 17.8mm,Anti glare glass<br/><br/>It becomes a comparison with the second generation of maxhub.',
      ForPrivacy: 'For privacy and performance, up to 16 gigabytes ddr4-4.8 million pixel flip over cameras are adopted, and when the web conference application is launched, the camera will automatically rise. <br/><br/>Auto zoom in on the speaker,Flips to the front only once',
      HighPower: 'With 40W * 3 high power speakers and an improved audio algorithm, you get a professional-studio-quality audio experience. <br/><br/>2.1 orbits the Sound | 40 W Powerful Speakers <br/><br/>* 3 speaker output is 2 * 10 W + 20 W (ウーファー).',
      EquippedWith: 'Equipped with six array microphones, a sound collection range of 8 meters and localization technology, it can collect sound accurately from the corner or from the front. <br/><br/>The AGC function * 4 adjusts the volume of the speaker to an appropriate level according to the distance between the speaker and the microphone, and realistically recreates the voices of conference participants. <br/>6-Element Microphone Array |Up to 8 Metres Voice Pickup |Voice Localisation | Automatic Gain |Noise Cancellation',
      FeaturesAnd: 'Features and writing comfort unique to digital, realize comfortable operation, wireless dongle, dedicated application "MAXHUB Share ", Type-C, X-link, etc. The ability to write and manipulate in both directions enables accurate communication in real time.<br/><br/> MAXHUB wireless dongles | MAXHUB Share |',
      PlugableDesign: 'The pluggable design makes necessary maintenance and upgrades easy. It is equipped with Windows, which is highly extensible and compatible, so it can be used from current internal systems without being unreasonable.<br/><br/> Windows 10',
      ImageSet: 'Image set',
      ConferenceRoomMiddle: 'Conference room',
      TheConference: 'The conference room is very suitable for a conference that requires concrete discussions, clear decisions.',
      ConferenceRoomHigh: 'Conference room',
      InTheConference: 'In the conference room many parties gather for announcements, group reports and negotiations.With maxhub you can easily share ideas and easily connect with people who are not on the spot.',
      RecommendedSize: 'Recommended size',
   },
   EMEET: {
      EmetOptimized: 'eMeet optimized 360 degree audio and video cover',
      TheExclusiveAI: 'The exclusive AI algorithm presents the active speaker is face perfectly on the display in order to respond to the voice and provides efficient communication for the whole team',
      ToSee: 'To see the whole room',
      The360Degree: 'The 360 degree conference camera captures all the details within a radius of 13 feet (4 m) at 4K resolution, outputs the HD1080P image, and covers the entire conference room without a blind angle.',
      SoundClearly: 'Sound clearly',
      SmartOmnidirectional: '8 smart omnidirectional microphones pick up audio from every angle within a radius of 5.5 meters (18 feet) and raise the voice quality to the next level.',
      ListenToAll: 'Listen to all syllables',
      The10W90dB: 'The 10W / 90dB speaker delivers the hi fi grade sound to the ear to clear the detail to clear. The original suspend cavity design avoids vibration noise, so both the microphone and the speaker work perfectly.',
      originalCreation: 'The original suspend cavity design avoids vibration noise, so both the microphone and the speaker work perfectly.',
      MaximizingPerformance: 'Maximizing productivity maximizing productivity',
      BestProfessional: 'Best professional hands free phone, 360 degree pickup positioning, ring light, 48Khz sampling rate, unique DSP algorithm voicia ®、 The first floating microphone cavity design, high performance speakers, is very easy to use, suitable for medium and large conference rooms.',
   },
   home: {
      AutoSpot: 'Auto Spot Cleaning',
      QuadruplEefficiency: '4X Efficiency Improvement',
      PhantasExplores: 'Phantas incorporates Gausiums groundbreaking Auto Spot Cleaning into its path planning. Under this mode, the robot scans the cleanliness of the nearby floor and autonomously performs spot cleaning where the waste is detected. When encountering wastes that',
      SeeMorel: 'See more',
      RobotWith: 'Robot with AI, floor polish',
      AnExper: 'An expert in robot vacuum cleaners',
      CleaningOil: 'Cleaning oil stains',
      OutdoorCleaning: 'Outdoor cleaning',
      OtherAreas: 'Other areas',
      HighQuality: 'High quality, very large screen. Smooth writing.',
      Degree: '360-degree camera',
      SpeechRecognition: 'Speech recognition, face recognition, gesture recognition',
      InVehicle: 'vehicle-mounted',
      FullScreen: 'Full screen, full vision.',
   },
   phantas: {
      SmartObstacle: 'Smart Obstacle Avoidance',
      PhantasDetects: 'Phantas detects static and dynamic obstacles from all directions and performs autonomous smart avoidance in real-time, generating collision-free paths within its workspaces. Unlike many off-the-shelf commercial cleaning robots, Phantas is powered by deep-l',
      OneStop: 'One stop self service',
      WhenCombined: 'Phantas is paired with a charging dock to minimize human intervention during operation. It is able to perform self-services by autonomously connecting with the docking station. An optional water tank trolley is available to save your effort for water refil.',
      AutoSpot: 'Auto Spot Cleaning',
      QuadrupleEfficiency: '4X Efficiency Improvement',
      PhantasExplores: 'Phantas incorporates Gausium’s groundbreaking Auto Spot Cleaning into its path planning. Under this mode, the robot scans the cleanliness of the nearby floor and autonomously performs spot cleaning where the waste is detected. When encountering wastes that',
      PhantasS1: 'Phantas S1 details ginseng number',
      ProductSize: 'Product size：',
      Products: 'Net weight of product：',
      SensorConfiguration: 'Cleaning function：',
      KiyoaraiWidth: 'Cleaning efficiency：',
      use: 'Floor cleaning, cleaning, dust push, Carpet Cleaning',
      pleonasm_title: 'Cleaning robots and robots × Enway',
      pleonasm_content: 'In 1922, GIGO robotics Corporation and enway started working together to introduce robots.We are involved in the development of a cleaning robot.<br/><br/>Since 2023, enway has launched authentic robotics and cleaning robots made by Shiga robotics Co., Ltd.'
   },
   vacuum40: {
      vacuum40: 'Commercial robots',
      ItIsCompatible: 'It is compatible with hard indoor floors and carpets, and is equipped with multiple functions such as vacuuming, sweeping, and removing trash. It is widely used in office buildings, hotels, exhibition halls, conference halls, schools, airports, etc.',
      ThoroughCleaning: 'Thorough cleaning and air purification',
      EachFunction: 'Multi-function in one unit: cleaning, dust collection, and propulsion dust are integrated.Compatible with: marble, abrasive, tile, carpet.<br/>- mist elimination: high efficiency of ultrasonic mist disinfection and sterilization <br/>The entire route is unmanned: self-charging, passing through the gate, and taking the elevator.<br/>Smart management: supports task scheduling, timing tasks and remote operation.<br/>Digitization operation: precision and visualization of data management',
      SimplifyingWorkflow: 'Workflow simplification',
      Vacuum40Can: 'Vacuum 40 makes it easy to clean without having to bother human hands. You need to detect the changes in the environment, update the map in real time and change the route, so you don t have to help you stay stuck. If you use an optional charging dock, you can charge the robot yourself. You can also remotely access the gausium mobile app to monitor and control cleaning work from anywhere.',
      Vacuum40Specifications: 'Vacuum 40 specifications',
      ProductSize: 'Item size：',
      BatteryCapacity: 'Battery capacity：',
      ContinuousUptime: 'Continuous operation time：',
      KiyoaraiWidth: 'Wash width：',
      SensorConfiguration: 'Sensor configuration：',
      DeviceDisposition: 'Lidar * 1, depth camera * 3, top view camera (optional), electronic anti-collision bar, anti-drop'
   },
   scrubber50: {
      scrubber50: 'Commercial cleaning robot',
      RobotsForCleaning: 'The cleaning, cleaning and dusting of the hard floors in the room are applied to a wide range of scenes such as shopping malls, super Marquette, hospitals, office buildings, hotels, transportation bases, industrial zones, schools.',
      Scrubber50Specification: 'Scrubber50 specifications',
      ProductSize: 'Item size：',
      BatteryCapacity: 'Battery capacity：',
      ContinuousUptime: 'Continuous operation time：',
      KiyoaraiWidth: 'Wash width：',
      SensorConfiguration: 'Sensor configuration：',
      ClimbingSlope: 'Climb gradient：',
      dispose: 'Lidar * 1, depth camera * 3, bilateral depth camera (optional), top view camera (optional), electronic anti-collision bar, anti-drop, negative pressure sensor'
   },
   scrubber75: {
      scrubber75: 'Commercial cleaning robot',
      ThisCleaning: 'It can be applied to a wide variety of indoor and outdoor scenes such as square, airport, station, parking, factory, warehouse, super Marquette and school. It has a function such as cleaning of floor, removal of oil stains, removal of dust, and prevention of foreign body closures.',
      Scrubber75Specification: 'Scrubber75 specifications',
      ProductSize: 'Item size：',
      BatteryCapacity: 'Battery capacity：',
      ContinuousUptime: 'Continuous operation time：',
      KiyoaraiWidth: 'Wash width：',
      SensorConfiguration: 'Sensor configuration：',
      configuration: '3D laser radar, depth camera, 2D laser radar, millimeter wave radar, electronic anti-collision bar'
   },
   sweeper111: {
      sweeper111: 'Commercial cleaning robot',
      RobotsForCleaning: 'The cleaning, cleaning and dusting of the hard floors in the room are applied to a wide range of scenes such as shopping malls, super Marquette, hospitals, office buildings, hotels, transportation bases, industrial zones, schools.',
      Sweeper111Specification: 'Sweeper111 specification',
      ProductSize: 'Item size：',
      BatteryCapacity: 'Battery capacity：',
      ContinuousUptime: 'Continuous operation time：',
      KiyoaraiWidth: 'Wash width：',
      TurningAngle: 'Angle of rotation：',
   },
   news: {
      newsCenter: 'News Center',
      learnMore: 'Learn more',
      noData: 'No data now',
      bannerTitle: 'Gaussian Robotics\' "Cleaning Robot" and " Meal Delivery Robot" are now being offered in Japan officially .',
      bannerContent: 'In 2023, we will start providing Gaussian Robotics\' "Cleaning Robot" and " Meal Delivery Robot" to Japan officially. 2022 is the year of our business collaboration with Gaussian Robotics, and we started to introduce their robots to Japan last year.We are also involved in the development of the "cleaning robot" system.。'
   },
   header: {
      information: 'Company Profile',
      inquiry: 'Contact Us',
   },
   footer: {
      privacyPolicy: 'Privacy Policy',
      rules: 'Terms Of Service',
      directory: 'Directory',
      privacyClause: 'Privacy policy terms of use',
      location: 'Location'
   },
   nav: {
      car: 'Vehicle Installation',
      phone: 'Mobile Phone',
      robot: 'Robot',
      smartHome: 'Home Intelligent',
      cleaningRobot: 'Cleaning Robot',
      news: 'NEWS',
      gohome: 'Go home',
      delivery:'Delivery Robot'
   },
   car: {
      car: 'Vehicle installation',
      FullScreen: 'Full screen',
      FullView: 'Full Vision',
      math: 'Digital Meter',
      adas: 'Advanced',
      safe: 'Driver-Assistance Systems',
      system: 'System Configuration',
      navigation: 'Intelligence navigation'
   },
   phone: {
      phone: 'Phone',
      second: '0.1 Seconds Capture',
      noUse: 'No Buttons Needed',
      ai: 'AI Image Denoising',
      lowLight: 'Low light Environment',
      fillLight: 'Auto Fill Light',
      beauty: 'Retouch',
      tracking: 'Real-time Tracking Function',
      focus: 'The Protagonist Is The Focus'
   },
   robot: {
      robot: 'Robot',
      math: 'AI Algorithm',
      identify: 'Voice recognition,Face recognition,Gesture Recognition',
      foot: 'High-degree-of-freedom quadruped robot',
      eye: 'Visual Identity',
      gesture: 'Gesture Recognition,Face Tracking, Visual Programming Environment',
      OpenSource: 'Open Source',
      any: 'Customize Any Gait Algorithm - Pace, Jump, Trot',
      person: 'Private Order',
      imagine: 'Infinite Imagination Space Brings You a Unique Design',
      robotDog: 'Mini Tits: Open source, ROS robot dog kit'
   },
   smartHome: {
      tv: {
         tv: 'TV',
         title: 'Strategize Inside',
         title_second: 'Win Outside',
         voice: 'Win Outside,Never Leave The Seat',
         voice_second: 'Sound source localization,  Echo cancellation, Intelligent noise reduction',
         face: 'Smart Face Tracking',
         face_second: 'Control the camera to track the face, Realize intelligent control and tracking',
         meet: 'Multiple applications, convenient meetings',
         meet_second: 'A variety of application functions to meet diversified meeting needs',
         system: 'Dual systems, multi-line tasks are not stuck',
         system_second: 'Dual Android/liunx+ Win dual system',
         control: 'Remote control, control the overall situation'

      },
      ebook: {
         ebook: 'Ebook'
      },
      speaker: {
         speaker: 'speaker',
         emeet: 'Emeet',
         communicate: 'Born for conference call！',
         lang: 'Unique AI Noise Reduction technology, highly reductive human voice, make sure your voice be heard clear and pure',
         m220: 'M220 Lite',
         wireless: 'Wireless adapter',
         noDriver: 'No driver required, plug and play',
         people: 'Perfectly fitting the meeting with 3 – 16 attendees',
         metting: 'Multi-person meeting',
         support: 'Support Skype for Business\\Teams\\Zoom',
         noVoice: 'AI Noise Reduction',
         mike: 'Multiple microphones',
         faceCommunicate: 'Easily enjoy clear sound quality as face-to-face communication',
         max: 'M2 Max',
         bluetooth: "Easily enjoy clear sound quality as face-to-face communication",
         switch: 'Compatible to all operation systems',
         clear: 'AI Mic Array',
         power: 'External Loudspeakers Supported',
         size: 'Dimensions (mm)',
         battery: 'Battery Capacity',
         distance: 'Bluetooth Distance',
         // connect:'Connect with BT device',
         output: 'Voice input',
         Capacity: 'Meeting with',
         BatteryLife: 'Battery Duration',
         twospeak: 'Double talk supported',
         audio: 'Audio algorithm',
         doa: 'DOA LED indicator',
         cascade: 'Daisy Chain Function',
         cascades: '',
         kg: 'Net Weight(g)',
         view: 'Field of view',
         vedio: 'Video Format',
         mikes: 'Microphone',
         trumpet: 'Trumpet',
         noise: 'Intelligent noise reduction',
         echo: 'Echo Cancel',
         light: 'Auto low light correction',
         tripod: 'Tripod',
         compatible: 'Compatible',
         add: 'Speech algorithm',
         connect: 'Realized by connect EMEET cascade line',
         focus_a: 'Fixed focus:f=4.6mm, FOV: 90',
         focus_b: 'Auto focus:FOV: 90°',
         focus_c: 'Fixed focus:f=4.6mm, FOV:90°',
         focus_d: 'AI smart humanoid tracking FOV: 90°',
         numberpeople_five: 'Up to 5 people',
         numberpeople_six: 'Up to 6 people',
         numberpeople_eight: 'Up to 8 people',
         numberpeople_sixteen: 'Up to 16 people',
         interface_a: 'USB',
         interface_b: 'BT, USB, AUX',
         interface_c: 'BT, USB, AUX, Dongle',
         interface_d: 'BT, USB, AUX, Dongle',
         interface_e: 'BT, USB, AUX, Dongle',
         interface_f: 'BT, USB, Dongle'
      }
   },
   consult: {
      title: 'Contact Us',
      contact: 'Contact us quickly to make cooperation easier',
      ask: 'Fill in the form below to obtain product information',
      setion_a: 'We accept inquiries about our services and material requests.',
      setion_b: 'Please enter the required information below, agree to the "Privacy Policy", and then click the submit button.',
      setion_c: 'Please be sure to fill in the required items (*).',
      setion_d: 'We will reply to the email address you entered, so please be sure to enter it correctly.',
      setion_e: 'If you are in a hurry, please send it directly to our email info@enway.co.jp. We will properly manage the personal information you provide.',
      setion_f: 'Except in special circumstances, we will not disclose or provide to third parties without my consent.',
      setion_g: 'Please read the "Privacy Policy" in advance and send it after agreeing to it。',
      companyName: 'Company Name',
      name: 'Name',
      number: 'Telephone Number',
      email: 'Email Address',
      content: 'Content',
      message: 'Information',
      agree: ' I agree',
      privacy: '* Privacy Policy',
      must: '* Required items',
      type: 'Type of cooperation',
      consultation: 'consultation',
      regionalAgent: 'Regional agent',
      cooperation: 'Market cooperation',
      error: {
         notNull_company: 'Company name cannot be empty',
         notNull_name: 'Name cannot be empty',
         notNull_number: 'Phone number cannot be empty',
         ture_number: 'Please enter the correct phone number format',
         notNull_email: 'E-mail can not be empty',
         ture_email: 'Please enter the correct email format',
         notNull_content: 'Please select content'
      },
      submit: 'Submit',
      submitSuccess: 'Information has been submitted successfully'
   },
   Information: {
      title: 'Corporate Information',
      setiona_title: 'Company Name:',
      setion_a: 'Enway Corporation',
      setionb_title: 'Created on:',
      setion_b: 'May 15, 2015 ',
      setionc_title: 'Location:',
      setion_c: 'KKS Building 4F, 3-1-2, Omorikita, Ota Ward, Tokyo',
      setione_title: 'Dealer Bank:',
      setion_e: 'Bank of Tokyo-Mitsubishi UFJ/Mizuho Bank',
      setionf_title: 'CEO:',
      setion_f: 'Liu　Zhisuo',
      setiong_title: 'TEL:',
      setion_g: '03 6404 6200',
      setion_h: 'View on Google Maps',
      Mission: {
         title: 'Mission',
         content: 'It is meaningless without any good idea or concept to change the "dream" to the "shape" by technology, design, and passion.',
         pcdetails: 'No good idea or concept is meaningless unless you make a "shape". Our mission is to realize hot thoughts for business and products in the real world. I want to be a partner who always shares a hot desire in the same position as a client and agrees with the trouble together. It supports the development of the service by the best technology and the design with such an idea.'
      },
      Vision: {
         title: 'Vision',
         content: 'It is realized that the person is centered, and the high level fusion of the digital (virtual) space and the physical (reality) space is realized',
         pcdetails: 'We aim not only for a single product but also to "eco system to keep ideas". Create a new value as an "engineering professional" that fills the ditch between "think tanks to organize issues" and "innovative Institute of science and technology".'
      },

   },
   Contact: {
      Contents: {
         cleaningRobot: 'Cleaning robot',
         MAXHUB: 'MAXHUB',
         eMeet: 'eMeet',
         robot: 'Robot',
         car: 'In vehicle',
         others: 'Others'
      },
      Tip: {
         title: "Please use the form below to inquire about our services.",
         setion_a: 'Please fill in the required information, agree to our "Privacy Policy" and click the "Send" button.',
         setion_b: "We will reply to the e-mail address you provide, so please make sure to enter the correct information.",
         setion_c: "If you have urgent matters, please call our service hotline or send an email to info@enway.co.jp",
         setion_d: "We will manage your personal information appropriately.",
         setion_e: "We will not disclose or provide your personal information to any third party without your permission unless there are special circumstances.",
         setion_f: 'Please read the "Privacy Policy" in advance and agree to it before sending your information.',
      },
      contact: 'Inquiry'
   },
   alert: {
      here: 'すでにこのページにあります'
   },
   privacyPolicy: {
      title: 'Privacy Policy',
      content: 'Enway Corporation (hereafter referred to as \'Enway\') recognises that it has a social responsibility to manage personal information appropriately and has established the following privacy policy to ensure that all employees are aware of the importance of protecting personal information and are committed to protecting personal information.',
      title_a: 'Purpose of use of Personal Data',
      content_a: 'The personal data we receive from you will be used to contact you, answer your questions, provide and inform you of our products and services, send you e-mails and materials in the course of sales activities, and for other legitimate purposes.',
      title_b: 'Management of Personal Data',
      content_b: 'In order to keep your personal data accurate and up-to-date and to prevent illegal access, loss, damage, falsification or leakage of personal data, we take the necessary measures to maintain a security system, improve our management system and provide thorough employee training, and implement safety measures to strictly manage your personal data.',
      title_c: 'Outsourcing of Personal Data',
      content_c: 'In order to achieve the purposes of use specified above, Enway may outsource the handling of the personal data provided by you to a subcontractor or partner. If the handling of personal information is outsourced to a third party, we will select a party that is deemed to be handling the personal information properly and provide the necessary and appropriate supervision to ensure that the selected third party manages the personal information concerned safely.',
      title_d: 'Provision of Personal Data to Third Parties',
      content_d: 'Enway will not provide personal information to any third party without the prior consent of the person concerned, except where required by the Personal Data Protection Act or other laws and regulations, or in special circumstances.',
      title_e: 'Disclosure and Correction of Personal Data',
      content_e1: 'Enway will promptly disclose, correct or delete your personal information if we are able to verify that the request is made by you, except in cases where such a request could seriously impede our business.',
      content_e2: 'If you wish to request disclosure, correction or deletion of personal data provided by you, please contact the contact point.',
      title_f: 'Compliance with and Review of Laws, Regulations and Codes of Conduct',
      content_f: 'Enway will comply with Japanese laws, regulations and other norms applicable to the personal data it holds and will review and improve the content of the above policy from time to time.'
   },
   rules: {
      title: 'Terms of Use',
      content: 'This website is operated by Enway Corporation ("Enway") and its affiliated companies. By using this website, you agree to these terms of use. Please note that these terms of use are subject to change.',
      title_a: 'Disclaimer',
      content_a: 'Enway and its affiliates do not guarantee the accuracy, usefulness, certainty, safety or any other aspect of the content of this website and accept no liability whatsoever for any damage resulting from the use of this website. The content of this website is subject to change or deletion without notice.',
      title_b1: 'Intellectual Property Rights',
      title_b2: 'Copyright',
      content_b: 'The copyright in the documents, graphics, logos, designs, photographs and other content on this website belongs to Enway or their creators. The reproduction, diversion or alteration of these contents without Enway\'s prior permission is prohibited, except for personal use or where expressly permitted by law.',
      title_c: 'Trademark Rights',
      content_c: 'The names of products and services of Enway or its affiliates used on this website are trademarks or registered trademarks of Enway or its affiliates respectively. Other company names, product and service names etc. are trade names, trademarks or registered trademarks of their respective companies.',
      title_d: 'About the product specifications',
      content_d: 'Product specifications on this website are subject to change without notice. Please be aware of this in advance.',
   },
   deliveryx1: {
      header_content: 'Where Technology Meets Affordability',
      banner: {
         title: 'X1, Where Technology Meets Affordability',
         content:'Delivery X1 is an autonomous delivery robot. It provides a cost-effective smart delivery solution for the hospitality industry where labor shortage and increasing labor costs are of top challenges. Being the first product of the delivery line, X1 performs basic point-to-point autonomous delivery of food, beverage, or various cargos. The autonomous delivery robot has advanced localization and environmental perception capabilities powered by Gausium’s industry-leading navigation technology and a cutting-edge sensor system. It also adopts advanced shock mitigation mechanisms to provide a stable, spill- proof delivery process.',
         end: 'Award:'
      },
      details: {
         title_a: 'Hospitality & Entertainment',
         title_b: 'Healthcare',
         title_c: 'Office Buildings',
         content_title: {
            title_a: 'Hospitality',
            title_b: 'Healthcare',
            title_c: 'Offices'
         },
         content: {
            title: 'Key Features',
            boxA: {
               title: 'Marker-less Deployment',
               content: 'Easy set-up without scanning QR location code, saving 75% deployment time while protecting interior design of the site'
            },
            boxB: {
               title: 'Smart Trays（X1 Pro）',
               content: 'Equipped with weight sensors and LED indicator lights, signaling the load status and automatically leaving after the load is emptied'
            },
            boxC: {
               title: 'Industry-leading Shock Mitigation',
               content: 'Adopting automotive-level independent suspension chassis to ensure the best shock mitigation effect for a stable, spill-proof delivery process'
            },
            boxD: {
               title: 'Navigating Narrow Aisles',
               content: 'Effortlessly passing through aisles as narrow as 65cm supported by high-precision sensors'
            },
            boxE: {
               title: 'High Performance Battery',
               content: 'Adopting lithium phosphate battery— higher thermal stability and 6X longer endurance than common ternary lithium batteries.'
            },
            boxF: {
               title: 'Multi-robot Collaboration',
               content: 'Able to realize intercommunication between robots to work collaboratively in large sites'
            }
         }
      },
      intro: {
         boxA: { header: 'SHORT-TERM ROI', title: 'Bring productivity up and costs down', content: 'With a load capacity up to 30kg, Delivery X1 can serve several tables at one go. It has a long uptime backed by large-capacity LFP batteries, and calculates the most efficient path to save energy consumption. These, plus X1’s affordable price, promise a quick ROI and help you cut down operating costs in a short time.' },
         boxA_list: {
            one: 'Up to 30kg load capacity',
            two: '14-20 hour uptime', three: 'Keenly priced'
         },
         boxB: { header: 'DELIVERY MADE EFFORTLESS', title: 'Simplify your service process', content: 'Delivery X1 features hassle-free deployment process. It does not require scanning location markers for assistance of positioning. The trays of the autonomous delivery robot are equipped with weight sensors and LED indicator lights that perceive and signal the load status. When the load is emptied by the customer, the robot will automatically leave for next tasks. With an optional AI camera for human detection, X1 Pro will proactively interact with people to help attract more traffic to your business.' },
         boxB_list: {
            one: 'Marker-less deployment',
            two: 'Smart load perception and indication',
            three: 'Remote-control mobile app',
            four: 'Human detection and interaction (optional)'
         },
         boxC: { header: 'OBSTACLE AVOIDANCE & SHOCK MITIGATION', title: 'Collision-free and Spill-proof', content: 'The navigation algorithms of Delivery X1 are based on a fusion of LiDAR and 3D depth cameras, which provide high-level environmental perception and smart obstacle avoidance. The automotive-level independent suspension chassis creates superb shock mitigation effect for a smooth and stable delivery experience of drinks or liquid dishes.' },
         boxC_list: {
            one: 'Smart obstacle avoidance',
            two: 'Independent Suspension Chassis'
         },
      },
      footer:{
         title:'Delux x 1 / X1 Pro specifications',
         list:{
            title_a:'Item size:',
            title_b:'Battery capacity:',
            title_c:'Continuous operation time:',
            title_d:'Maximum moving speed:',
            title_e:'Climbing gradient:',
            content_a:'515（L）* 523（W）* 1286（H）mm (X1)',
            content_aa:'515（L）* 531（W）* 1310（H）mm (X1 Pro)',
            content_b:'20 Ah',
            content_c:'14 - 20h',
            content_d:'1.2m/s',
            content_e:'5°',
         }
      }
   }
}
