import { Message } from 'element-ui'
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)



const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact'),
  },
  {
    path: '/car',
    name: 'car',
    component: () => import('@/views/car'),
  },
  {
    path: '/tv',
    name: '/tv',
    component: () => import('@/views/tv'),
  },
  {
    path: '/speaker',
    name: '/speaker',
    component: () => import('@/views/speaker'),
  },
  {
    path: '/robotDog',
    name: '/robotDog',
    component: () => import('@/views/robotDog'),
  },
  {
    path: '/phantas',
    name: '/phantas',
    component: () => import('@/views/phantas'),
  },
  {
    path: '/vacuum40',
    name: '/vacuum40',
    component: () => import('@/views/vacuum40'),
  },
  {
    path: '/scrubber50',
    name: '/scrubber50',
    component: () => import('@/views/scrubber50'),
  },
  {
    path: '/scrubber75',
    name: '/scrubber75',
    component: () => import('@/views/scrubber75'),
  },
  {
    path: '/sweeper111',
    name: '/sweeper111',
    component: () => import('@/views/sweeper111'),
  },
  {
    path: '/information',
    name: '/information',
    component: () => import('@/views/information'),
  },
  {
    path: '/news',
    name: '/news',
    component: () => import('@/views/news'),
  },
  {
    path: '/news/:id',
    name: '/news/:id',
    component: () => import('@/views/newsContent'),
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import('@/views/privacyPolicy'),
  },
  {
    path: '/rules',
    name: 'rules',
    component: () => import('@/views/rules'),
  },
  {
    path: '/deliveryx1',
    name: 'deliveryx1',
    component: () => import('@/views/deliveryx1')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.getElementById('app').scrollTop = 0
  next()
});

export default router